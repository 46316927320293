import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { API } from '@service/api';

@Component({
  selector: 'app-posture-list',
  templateUrl: './posture.component.html',
  styleUrls: ['./identity.scss'],
})
export class PostureComponent implements OnInit, OnChanges {
  @Input() searchValue = '';
  @Input() isRefresh = false;
  pageSize = 10;
  pageIndex = 1;
  total = 0;
  listData: any[] = [];
  tableLoading = false;
  isPostureVisible = false;
  postureId: any;
  postureInfo: any;
  isRefreshPosture = false;

  constructor(modalSrv: NzModalService, private api: API, public http: _HttpClient, public notify: NzNotificationService) {
    modalSrv.closeAll();
  }

  ngOnInit(): void {
    this.refreshList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.pageIndex = 1;
    this.refreshList();
  }

  onPostureVisibleChange(ev: any): void {
    this.isPostureVisible = ev;
  }

  onRefreshPosture(ev: boolean): void {
    this.pageIndex = 1;
    this.refreshList();
  }

  onEdit(data: any): void {
    this.postureId = data.id;
    this.postureInfo = data.detail;
    this.postureInfo.name = data.postureName;
    this.isPostureVisible = true;
  }

  onDelete(data: any): void {
    if (data.id) {
      this.http.delete(this.api.DEl_POSTURE_CHECK(data.id)).subscribe((e: any) => {
        if (e.code === 200) {
          this.notify.success('删除成功', '');
          this.pageIndex = 1;
          this.refreshList();
        }
      });
    }
  }

  pageChange(ev: any): void {
    this.pageIndex = ev;
    this.refreshList();
  }

  pageSizeChange(ev: any): void {
    this.pageSize = ev;
    this.refreshList();
  }

  refreshList(): void {
    const par = { name: this.searchValue, pageSize: this.pageSize, pageNum: this.pageIndex };
    this.tableLoading = true;
    this.http.post(this.api.POSTURE_LIST, par).subscribe(
      (e: any) => {
        if (e.code === 200) {
          this.total = e.total;
          this.listData = e.data || [];
          this.tableLoading = false;
        }
      },
      () => (this.tableLoading = false),
    );
  }
}

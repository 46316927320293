<!--[nzShowButton]='allowUpload()'-->
<nz-upload
  [nzAction]="uploadUrl"
  [nzListType]="type == 'image' ? 'picture-card' : 'text'"
  [(nzFileList)]="files"
  [nzDisabled]="readonly || disabled"
  [nzPreview]="handlePreview"
  [nzShowUploadList]="showButton"
  [nzShowButton]="files.length < max || showUploadButton"
  (nzChange)="fileChanged($event)"
>
  <button *ngIf="type == 'file'" nz-button type="button" [disabled]="!allowUpload()">
    <i nz-icon nzType="upload"></i><span>上传附件</span>
  </button>
  <ng-container *ngIf="type == 'image'">
    <i nz-icon nzType="plus"></i>
    <div class="ant-upload-text">{{ title }}</div>
  </ng-container>
</nz-upload>

<nz-modal
  *ngIf="previewVisible"
  [(nzVisible)]="previewVisible"
  [nzContent]="modalContent"
  [nzFooter]="null"
  (nzOnCancel)="previewVisible = false"
  [nzWidth]="modalWidth"
>
  <ng-template #modalContent>
    <div class="list">
      <img id="dowebok" class="image" [src]="previewImage" style="width: 100%" alt="" (click)="previewVisible = false" />
    </div>
  </ng-template>
</nz-modal>

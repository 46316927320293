<div>
  <nz-table
    nzSize="middle"
    [nzFrontPagination]="false"
    [nzData]="listData"
    [nzLoading]="tableLoading"
    [nzScroll]="{ x: '1400px', y: '1000px' }"
  >
    <thead>
      <tr>
        <th nzWidth="100px">序号</th>
        <th>名称</th>
        <th>详情</th>
        <th nzWidth="100px" nzRight>操作</th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr *ngFor="let data of listData; let i = index">
          <td nzWidth="100px">{{ (pageIndex - 1) * pageSize + i + 1 }}</td>
          <td>{{ data?.postureName }}</td>
          <td>{{ data?.details }}</td>
          <td nzWidth="100px" nzRight>
            <a (click)="onEdit(data)">编辑</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a nz-popconfirm nzPopconfirmTitle="确定要删除该数据吗？" (nzOnConfirm)="onDelete(data)">删除</a>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
  <div class="table-pagination-box">
    <nz-pagination
      [nzPageIndex]="pageIndex"
      [nzTotal]="total"
      nzShowSizeChanger
      [nzPageSize]="pageSize"
      (nzPageIndexChange)="pageChange($event)"
      (nzPageSizeChange)="pageSizeChange($event)"
      [nzShowTotal]="totalTemplate"
    >
      <ng-template #totalTemplate let-total>共{{ total }}条</ng-template>
    </nz-pagination>
  </div>
</div>

<!--编辑姿势检查-->
<app-posture-edit
  *ngIf="isPostureVisible"
  [isVisible]="isPostureVisible"
  [id]="postureId"
  [info]="postureInfo"
  (isVisibleChange)="onPostureVisibleChange($event)"
  (isRefresh)="onRefreshPosture($event)"
>
</app-posture-edit>

<!--新增编辑配置-->
<nz-modal *ngIf="isVisible" [(nzVisible)]="isVisible" [nzTitle]="title" nzClosable="false" [nzWidth]="'900px'">
  <ng-container *nzModalContent>
    <form nz-form [formGroup]="form">
      <nz-form-item>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzRequired>名称</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <input nz-input formControlName="addressName" placeholder="请输入" />
        </nz-form-control>
      </nz-form-item>
    </form>

    <nz-table #payDataTem [nzFrontPagination]="false" nzSize="small" [nzData]="details">
      <thead>
        <tr>
          <th nzWidth="80px">序号</th>
          <th><span class="form-require-icon">*</span>类型</th>
          <th>指定IP值</th>
          <th>起始范围IP</th>
          <th>结止范围IP</th>
          <th nzWidth="120px" nzRight><a (click)="onAddIp()">+新增</a></th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr *ngFor="let data of payDataTem.data; let i = index">
            <td nzWidth="80px">{{ i + 1 }}</td>
            <td>
              <nz-form-control>
                <nz-select [(ngModel)]="data.ipType" nzPlaceHolder="请选择">
                  <nz-option *ngFor="let data of ipTypes" [nzLabel]="data.name" [nzValue]="data.id"></nz-option>
                </nz-select>
              </nz-form-control>
            </td>
            <td>
              <input nz-input [(ngModel)]="data.ip" placeholder="请输入#.#.#.#" />
            </td>
            <td>
              <input nz-input [(ngModel)]="data.ipStart" placeholder="请输入#.#.#.#" />
            </td>
            <td><input nz-input [(ngModel)]="data.ipEnd" placeholder="请输入#.#.#.#" /></td>
            <td nzWidth="120px" nzRight><a (click)="onDelIp(i)">删除</a></td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </ng-container>
  <div *nzModalFooter>
    <div style="margin-top: -5px">
      <button nz-button nzType="default" (click)="close()">取消</button>
      <button nz-button nzType="primary" [nzLoading]="loading" (click)="submit()" [disabled]="form.invalid">确定</button>
    </div>
  </div>
</nz-modal>

<!--新增编辑身份-->
<nz-modal *ngIf="isVisible" [(nzVisible)]="isVisible" [nzTitle]="title" nzClosable="false" [nzWidth]="'1000px'">
  <ng-container *nzModalContent>
    <form nz-form [formGroup]="form">
      <nz-form-item>
        <nz-form-label [nzSm]="5" [nzXs]="24" nzRequired>名称</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <input nz-input formControlName="name" placeholder="请输入" />
        </nz-form-control>
      </nz-form-item>

      <div>
        <div nz-row [nzGutter]="24">
          <div nz-col [nzSpan]="5"></div>
          <div nz-col [nzSpan]="18" class="add-box">
            <div class="add-each" *ngFor="let role of roleAttributes">
              {{ role }}
              <div class="close-icon" (click)="onDeleteRole(role)">x</div>
            </div>
          </div>
        </div>

        <nz-form-item>
          <nz-form-label [nzSm]="5" [nzXs]="24">身份标签</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <div (mouseover)="onFocusRole($event)" (mouseleave)="onLeaveRole($event)">
              <input
                nz-input
                formControlName="roleAttribute"
                (keydown.enter)="onRoleChange(form.value.roleAttribute)"
                placeholder="请输入"
              />
              <div *ngIf="roleVisible && roles.length > 0">
                <div class="input-selected-box">
                  <div class="input-selected-each" *ngFor="let op of roles" (click)="onRoleChange(op)">{{ op }}</div>
                </div>
              </div>
            </div>
          </nz-form-control>
        </nz-form-item>

        <!--        <nz-form-item>-->
        <!--          <nz-form-label [nzSm]='5' [nzXs]='24' nzRequired>关联角色</nz-form-label>-->
        <!--          <nz-form-control [nzSm]='18' [nzXs]='24'>-->
        <!--            <nz-filter-trigger [nzDropdownMenu]='menu' style='position: relative; left: -4px'>-->
        <!--              <input-->
        <!--                nz-input-->
        <!--                style='width: 714px'-->
        <!--                formControlName='roleAttribute'-->
        <!--                (keydown.enter)='onRoleChange(form.value.roleAttribute)'-->
        <!--                placeholder='请输入'-->
        <!--                (focus)='onFocusRole($event)'-->
        <!--                (mouseover)='onFocusRole($event)'-->
        <!--              />-->
        <!--            </nz-filter-trigger>-->
        <!--            <nz-dropdown-menu #menu='nzDropdownMenu'>-->
        <!--              <div class='row-box' style='width: 100%' *ngIf='roleVisible'>-->
        <!--                <div class='row flex-center' *ngFor='let op of roles' (click)='onRoleChange(op)'>{{ op }}</div>-->
        <!--              </div>-->
        <!--            </nz-dropdown-menu>-->
        <!--          </nz-form-control>-->
        <!--        </nz-form-item>-->
      </div>

      <nz-form-item>
        <nz-form-label [nzSm]="5" [nzXs]="24" nzRequired>类型</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择" formControlName="type">
            <nz-option *ngFor="let data of types" [nzLabel]="data.name" [nzValue]="data.value"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <!--      <nz-form-item>-->
      <!--        <nz-form-label [nzSm]="5" [nzXs]="24">管理员</nz-form-label>-->
      <!--        <nz-form-control [nzSm]="18" [nzXs]="24">-->
      <!--          <nz-switch nzCheckedChildren="是" nzUnCheckedChildren="否" formControlName="isAdmin"></nz-switch>-->
      <!--        </nz-form-control>-->
      <!--      </nz-form-item>-->

      <nz-form-item>
        <nz-form-label [nzSm]="5" [nzXs]="24" nzRequired>注册类型</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择" formControlName="enrollment" [nzDisabled]="disabled">
            <nz-option *ngFor="let data of enrolTypes" [nzLabel]="data.name" [nzValue]="data.value"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="form.value.enrollment === 'ottca'">
        <nz-form-label [nzSm]="5" [nzXs]="24">CA证书</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择" formControlName="ottca" [nzDisabled]="disabled">
            <nz-option *ngFor="let data of certInfos" [nzLabel]="data.name" [nzValue]="data.value"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="5" [nzXs]="24">身份验证策略</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择" formControlName="authPolicyId">
            <nz-option *ngFor="let data of authInfos" [nzLabel]="data.name" [nzValue]="data.value"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="5" [nzXs]="24">外部ID</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <input nz-input placeholder="请输入" formControlName="externalId" />
        </nz-form-control>
      </nz-form-item>
      <!--      <nz-form-item *ngIf="form.value.enrollment === 'updb'">-->
      <!--        <nz-form-label [nzSm]="5" [nzXs]="24">UPDB USERNAME</nz-form-label>-->
      <!--        <nz-form-control [nzSm]="18" [nzXs]="24">-->
      <!--          <input-->
      <!--            nz-input-->
      <!--            placeholder="请输入"-->
      <!--            formControlName="updb"-->
      <!--            [readonly]="disabled"-->
      <!--            [style]="disabled ? { background: 'rgba(0, 0, 0, 0.04)', color: 'rgba(0, 0, 0, 0.25)' } : {}"-->
      <!--          />-->
      <!--        </nz-form-control>-->
      <!--      </nz-form-item>-->

      <nz-form-item *ngIf="form.value.type === 'Device'">
        <nz-form-label [nzSm]="5" [nzXs]="24" nzFor="deviceIp">IP地址</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请填写IP地址">
          <input nz-input formControlName="deviceIp" placeholder="请输入#.#.#.#" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="form.value.type === 'Device'">
        <nz-form-label [nzSm]="5" [nzXs]="24" nzFor="deviceIp">MAC地址</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请填写MAC地址">
          <input nz-input formControlName="deviceMac" placeholder="请输入#:#:#:#:#:#" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="form.value.type === 'Device'">
        <nz-form-label [nzSm]="5" [nzXs]="24" nzFor="deviceType" nzRequired>设备类型</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select name="select-validate" nzPlaceHolder="请选择" nzAllowClear nzShowSearch formControlName="deviceType">
            <nz-option *ngFor="let item of typeOptions" [nzValue]="item?.typeValue" [nzLabel]="item?.typeName"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="form.value.deviceType == '7' && form.value.type === 'Device'">
        <nz-form-label [nzSm]="5" [nzXs]="24" nzFor="workMode" nzRequired>工作模式</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select name="select-validate" nzPlaceHolder="请选择" nzAllowClear nzShowSearch formControlName="workMode">
            <nz-option [nzValue]="'1'" [nzLabel]="'网关'"></nz-option>
            <nz-option [nzValue]="'2'" [nzLabel]="'透传'"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <!--    <nz-form-item *ngIf="form.value.deviceType == '7'">-->
      <!--      <nz-form-label [nzSm]='5' [nzXs]='24' nzFor='deviceLanIp'>LAN地址</nz-form-label>-->
      <!--      <nz-form-control  [nzSm]="18" [nzXs]='24' nzErrorTip='请填写LAN地址'>-->
      <!--        <input nz-input formControlName='deviceLanIp' placeholder='请输入' />-->
      <!--      </nz-form-control>-->
      <!--    </nz-form-item>-->

      <!--    <nz-form-item *ngIf="form.value.deviceType == '7'">-->
      <!--      <nz-form-label [nzSm]='5' [nzXs]='24' nzFor='deviceGateWayIp'>网关地址</nz-form-label>-->
      <!--      <nz-form-control  [nzSm]="18" [nzXs]='24' nzErrorTip='请填写网关地址'>-->
      <!--        <input nz-input formControlName='deviceGateWayIp' placeholder='请输入' />-->
      <!--      </nz-form-control>-->
      <!--    </nz-form-item>-->

      <nz-form-item *ngIf="form.value.type === 'Device'">
        <nz-form-label [nzSm]="5" [nzXs]="24" nzFor="roomId">所在机房</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select name="select-validate" nzPlaceHolder="请选择" nzAllowClear nzShowSearch formControlName="roomId">
            <nz-option *ngFor="let item of roomOptions" [nzValue]="item.id" [nzLabel]="item.roomName"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="this.id && form.value.type === 'Device'">
        <nz-form-label [nzSm]="5" [nzXs]="24" nzFor="status" nzRequired>状态</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <!-- nzMode="multiple"-->
          <nz-select name="select-validate" nzPlaceHolder="请选择" nzAllowClear nzShowSearch formControlName="status">
            <nz-option [nzValue]="'1'" [nzLabel]="'在线'"></nz-option>
            <nz-option [nzValue]="'2'" [nzLabel]="'离线'"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="form.value.type === 'Device'">
        <nz-form-label [nzSm]="5" [nzXs]="24" nzFor="isMerge" nzRequired>合并显示</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select name="select-validate" nzPlaceHolder="请选择" nzAllowClear nzShowSearch formControlName="isMerge">
            <nz-option [nzValue]="true" [nzLabel]="'是'"></nz-option>
            <nz-option [nzValue]="false" [nzLabel]="'否'"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="form.value.type === 'Device' && form.value.deviceType == '7'">
        <nz-form-label [nzSm]="5" [nzXs]="24" nzFor="deviceLanIp">量子密钥数据加密</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select name="select-validate" nzPlaceHolder="请选择" nzAllowClear nzShowSearch formControlName="isSecret">
            <nz-option [nzValue]="true" [nzLabel]="'是'"></nz-option>
            <nz-option [nzValue]="false" [nzLabel]="'否'"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>

  <div *nzModalFooter>
    <div style="margin-top: -5px">
      <button nz-button nzType="default" (click)="close()">取消</button>
      <button nz-button nzType="primary" [nzLoading]="loading" (click)="submit()" [disabled]="form.invalid">确定</button>
    </div>
  </div>
</nz-modal>

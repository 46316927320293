import { Injectable } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { Observable } from 'rxjs';
import { API } from './api';
import { ShowyePageApiPath, ShowyePageApiService, ShowyePageUtilService } from '@showye/showye-page-base';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/**
 * 接口服务，专用从服务器请求数据。缓存管理也在这里进行。
 *
 *
 */
export class NbApiService extends ShowyePageApiService {
  constructor(protected baseApi: ShowyePageApiPath, private api: API, protected http: _HttpClient, protected coder: ShowyePageUtilService) {
    super(baseApi, http, coder);
  }

  public domainTree(): Observable<any> {
    return this.http.get(this.api.DOMAIN_TREE);
  }

  public getSameLevelDomainList(id: any): Observable<any> {
    return this.http.get(this.api.GET_SAME_LEVEL_DOMAIN_LIST(id));
  }

  public roleList(param: any): Observable<any> {
    return this.http.post(this.api.ROLE_LIST, param);
  }

  public roleAdd(param: any): Observable<any> {
    return this.http.post(this.api.ROLE_ADD, param);
  }

  public roleInfo(id: any): Observable<any> {
    return this.http.get(this.api.ROLE_INFO(id));
  }

  public roleEdit(param: any): Observable<any> {
    return this.http.post(this.api.ROLE_EDIT, param);
  }

  public roleDelete(id: any): Observable<any> {
    return this.http.delete(this.api.ROLE_DELETE(id));
  }

  public roleConfig(param: any): Observable<any> {
    return this.http.post(this.api.ROLE_CONFIG, param);
  }

  public allRoles(): Observable<any> {
    return this.http.get(this.api.ALL_ROLES);
  }

  public getRoleListByDomainId(domainId: any, param: any): Observable<any> {
    return this.http.post(this.api.GET_ROLE_LIST_BY_DOMAIN(domainId), param);
  }

  public menuTree(): Observable<any> {
    return this.http.get(this.api.MENU_TREE);
  }

  public myperms(): Observable<any> {
    return this.http.get(this.api.MY_PERMS);
  }

  public menuDelete(param: any): Observable<any> {
    return this.http.post(this.api.MENU_DELETE, param);
  }

  public buttonTree(): Observable<any> {
    return this.http.get(this.api.BUTTON_TREE);
  }

  public buttonAdd(param: any): Observable<any> {
    return this.http.post(this.api.BUTTON_ADD, param);
  }

  public buttonEdit(param: any): Observable<any> {
    return this.http.post(this.api.BUTTON_EDIT, param);
  }

  public buttonDelete(id: any): Observable<any> {
    return this.http.delete(this.api.BUTTON_DELETE(id));
  }

  // 枚举字典相关
  public enumList(par: any): Observable<any> {
    return this.http.post(this.api.ENUM_LIST, par);
  }

  // 权限组列表
  public authGroupList(): Observable<any> {
    return this.http.get(this.api.AUTH_GROUP_LIST);
  }

  // 权限组新增
  public authGroupAdd(par: any): Observable<any> {
    return this.http.post(this.api.AUTH_GROUP_ADD, par);
  }

  // 权限组编辑
  public authGroupEdit(par: any): Observable<any> {
    return this.http.post(this.api.AUTH_GROUP_EDIT, par);
  }

  // 权限组删除
  public authGroupDelete(id: any): Observable<any> {
    return this.http.delete(this.api.AUTH_GROUP_DELETE(id));
  }

  public addConfig(par: any): Observable<any> {
    return this.http.post(this.api.ADD_CONFIG, par);
  }

  public configTypes(): Observable<any> {
    return this.http.get(this.api.CONFIG_TYPES);
  }

  // 根据主体和部门用户列表下拉选择
  public getUserListByParam(par: any): Observable<any> {
    return this.http.post(this.api.GET_USER_LIST, par);
  }

  public getUserListByDomain(domainId: any, par: any): Observable<any> {
    return this.http.post(this.api.GET_USER_LIST_BY_DOMAIN(domainId), par);
  }

  // 用户列表
  public userList(par: any): Observable<any> {
    return this.http.post(this.api.USER_LIST, par);
  }

  // 新增用户
  public addUser(par: any): Observable<any> {
    return this.http.post(this.api.ADD_USER, par);
  }

  // 新增ott用户
  public addOTTUser(par: any): Observable<any> {
    return this.http.post(this.api.ADD_OTT_USER, par);
  }

  // 编辑用户
  public editUser(par: any): Observable<any> {
    return this.http.post(this.api.EDIT_USER, par);
  }

  public editAvatar(par: any): Observable<any> {
    return this.http.post(this.api.EDIT_AVATAR, par);
  }

  // 删除用户
  public deleteUser(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_USER(id));
  }

  public initPwd(id: any): Observable<any> {
    return this.http.get(this.api.INIT_PWD(id));
  }

  // 查看用户详情
  public userInfo(id: any): Observable<any> {
    return this.http.get(this.api.USER_INFO(id));
  }

  // 修改密码
  public changeMyPassword(param: any): Observable<any> {
    return this.http.post(this.api.MY_CHANGE_PASSWORD, param);
  }

  public database(): Observable<any> {
    return this.http.get(this.api.DATA_BASE);
  }

  public data(): Observable<any> {
    return this.http.get(this.api.DATA_BASE_DATA);
  }

  public listLog(param: any): Observable<any> {
    return this.http.post(this.api.LIST_LOG, param);
  }
  public getImgCode(): Observable<any> {
    return this.http.get(this.api.CAPTCHA_IMAGE);
  }
}

import { Component, Input } from '@angular/core';
import { NzUploaderFile } from './uploader.entity';

@Component({
  selector: 'app-uploader-list',
  templateUrl: './uploader-list.component.html',
  styleUrls: ['./uploader.scss'],
})
export class UploaderListComponent {
  @Input()
  public downloadUrl = '';
  @Input()
  public token: any;
  @Input()
  public files: NzUploaderFile[] = [];
  getSuffix(name: string): string {
    return !name
      ? ''
      : name.lastIndexOf('.') >= 0 && name.lastIndexOf('.') < name.length - 1
      ? name.substr(name.lastIndexOf('.') + 1)
      : name.substr(name.length > 4 ? name.length - 4 : 0);
  }

  getSimpleName(name: string): string {
    return name.length > 10 ? name.substr(0, 6) + ' ...' : name;
  }

  getPath(e: string): string {
    return (
      (e.startsWith('http') ? e : (this.downloadUrl ? this.downloadUrl : '') + e) +
      (this.token && this.token.length > 0 ? '?token=' + this.token : '')
    );
  }

  constructor() {}
}

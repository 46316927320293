import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { ListComponent } from './list.component';
import { LinkRoutingModule } from './link-routing.module';
import { LinkHistoryComponent } from './link-history.component';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

const COMPONENTS = [ListComponent, LinkHistoryComponent];

@NgModule({
  imports: [
    SharedModule,
    LinkRoutingModule,
    NzSelectModule,
    NzSwitchModule,
    NzPaginationModule,
    NzBadgeModule,
    NzDividerModule,
    NzTagModule,
    NzDatePickerModule,
  ],
  declarations: [...COMPONENTS],
  entryComponents: COMPONENTS,
  exports: [...COMPONENTS],
})
export class LinkModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { FormBuilder, Validators } from '@angular/forms';
import { API } from '@service/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ShowyePageAuthService } from '@showye/showye-page-base';

@Component({
  selector: 'app-identity-edit',
  templateUrl: './identity-edit.component.html',
  styleUrls: ['./identity.scss'],
})
export class IdentityEditComponent implements OnInit {
  @Input() id: any;
  @Input() info: any;
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() isRefresh = new EventEmitter<boolean>();

  set visible(ev: boolean) {
    this.isVisible = ev;
    this.isVisibleChange.emit(ev);
  }

  // tslint:disable-next-line:typedef
  get visible() {
    return (this.isVisible = false);
  }

  // 新增编辑表单
  form: any;
  title = '新增身份';
  loading = false;

  types: any[] = [
    { name: '设备', value: 'Device' },
    // { name: '服务', value: 'Service' },
    // { name: '用户', value: 'User' },
  ];
  enrolTypes: any[] = [
    { name: '一次性令牌', value: 'ott' },
    // { name: '证书', value: 'ottca' },
    // { name: 'UPDB', value: 'updb' },
  ];
  certInfos: any[] = [];
  authInfos: any[] = [];
  downloadUrl = this.api.UPLOAD;
  uploadUrl = this.api.UPLOAD;
  token: any = this.auth.token;
  disabled = false;
  roleVisible = false;
  roles: any[] = [];
  roleAttributes: any[] = [];
  allRoles: any[] = [];
  typeOptions: any[] = [
    // { label: '量子安全路由器', value: '1' },
    // { label: '摄像头', value: '2' },
    // { label: '显示屏', value: '3' },
    // { label: 'VPN', value: '4' },
    // { label: '情报板', value: '5' },
    // { label: '服务器', value: '6' },
  ];
  statusOptions = [
    { label: '在线', value: '1' },
    { label: '离线', value: '2' },
    { label: '报警', value: '3' },
    { label: '禁用', value: '4' },
    { label: '已删除', value: '99' },
  ];
  roomOptions: any[] = [
    // { label: '机房1', value: 1 },
  ];

  constructor(
    private api: API,
    private fb: FormBuilder,
    private auth: ShowyePageAuthService,
    public http: _HttpClient,
    public msg: NzMessageService,
    public notify: NzNotificationService,
  ) {
    this.form = fb.group({
      name: [null, [Validators.required, Validators.maxLength(50)]],
      type: ['Device', [Validators.required]],
      roleAttribute: [null, [Validators.maxLength(50)]],
      roleAttributes: [null],
      isAdmin: [false],
      enrollment: ['ott', [Validators.required]],
      authPolicyId: [null],
      externalId: [null, [Validators.maxLength(50)]],
      ottca: [null],
      updb: [null],
      deviceMac: [null, [Validators.pattern(/^([0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2}$/)]],
      deviceIp: [null, [Validators.pattern(/^((25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/)]],
      // 只有选择类型选择设备的时候需要必填
      deviceType: [null],
      workMode: [null],
      roomId: [null],
      status: [null],
      // 只有选择类型选择设备的时候需要必填
      isMerge: [null],
      deviceLanIp: [null],
      deviceGateWayIp: [null],
      isSecret: [null],
    });
  }

  ngOnInit(): void {
    this.getRoleByIDENTITYS();
    this.id ? (this.title = '编辑身份') : (this.title = '新增身份');
    this.getCasList();
    this.getAuthList();
    this.getRoomList();
    this.getDeviceTypeList();
  }

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }

  onFocusRole(ev: any): void {
    this.roleVisible = true;
  }

  onLeaveRole(ev: any): void {
    this.roleVisible = false;
  }

  getRoomList(): void {
    this.http.get(this.api.GET_ROOM_LIST).subscribe((e) => {
      this.roomOptions = e.data;
    });
  }

  getDeviceTypeList(): void {
    this.http.get(this.api.GET_DEVICE_TYPE_LIST).subscribe((e) => {
      this.typeOptions = e.data;
    });
  }

  onRoleChange(ev: any): void {
    if (ev && ev.length <= 50) {
      let isHas = false;
      this.roleAttributes.forEach((x: any) => {
        if (x === ev) {
          isHas = true;
        }
      });
      if (!isHas) {
        this.roleAttributes.push(ev);
        this.roles = this.roles.filter((x: any) => x !== ev);
      }
      this.form.patchValue({ roleAttribute: null, roleAttributes: this.roleAttributes });
    }
  }

  onDeleteRole(ev: any): void {
    this.roleAttributes = this.roleAttributes.filter((x: any) => x !== ev);
    let isHas = false;
    this.roles.forEach((x: any) => {
      if (x === ev) {
        isHas = true;
      }
    });
    if (!isHas) {
      this.allRoles.forEach((z: any) => {
        if (ev === z) {
          this.roles.push(ev);
        }
      });
    }
    this.form.patchValue({ roleAttributes: this.roleAttributes });
  }

  // 获取角色列表
  getRoleByIDENTITYS(): void {
    this.http.get(this.api.IDENTITY_ROLES).subscribe((e: any) => {
      if (e.code === 200) {
        this.roles = this.allRoles = e.data || [];
        if (this.id) {
          this.info.roleAttributes = this.info.roles;
          this.info.isAdmin = this.info.admin;
          this.info.enrollment = this.info.enrollmentType;
          this.info.ottca = this.info.enrollmentValue;
          this.info.updb = this.info.enrollmentValue;
          this.roleAttributes = this.info.roleAttributes || [];
          this.form.reset(this.info);
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      }
    });
  }

  // 获取证书下拉
  getCasList(): void {
    this.http.get(this.api.CA_LIST).subscribe((e: any) => {
      if (e.code === 200) {
        this.certInfos = e.data || [];
      }
    });
  }

  // 获取下拉
  getAuthList(): void {
    this.http.get(this.api.AUTH_LIST).subscribe((e: any) => {
      if (e.code === 200) {
        this.authInfos = e.data || [];
      }
    });
  }

  submit(): void {
    const formValue = this.form.value;
    switch (formValue.enrollment) {
      case 'ott':
        formValue.enrollment = { ott: true };
        break;
      case 'ottca':
        formValue.enrollment = { ottca: formValue.ottca };
        break;
      case 'updb':
        formValue.enrollment = { updb: formValue.updb };
        break;
      default:
        break;
    }
    const par = {
      name: formValue.name,
      type: formValue.type,
      isAdmin: formValue.isAdmin,
      roleAttributes: formValue.roleAttributes,
      enrollment: formValue.enrollment,
      authPolicyId: formValue.authPolicyId,
      externalId: formValue.externalId,
      isSecret: formValue.isSecret,
      deviceGateWayIp: formValue.deviceGateWayIp,
      deviceLanIp: formValue.deviceLanIp,
      isMerge: formValue.isMerge,
      status: formValue.status,
      roomId: formValue.roomId,
      workMode: formValue.workMode,
      deviceType: formValue.deviceType,
      deviceIp: formValue.deviceIp,
      deviceMac: formValue.deviceMac,
    };
    if (formValue.type === 'Device') {
      if (!formValue.deviceType) {
        this.notify.error('请选择设备类型', '');
        return;
      }

      if (formValue.isMerge === null) {
        this.notify.error('请选择是否合并显示', '');
        return;
      }
    }
    this.loading = true;
    if (!this.id) {
      this.http.post(this.api.IDENTITY_ADD, par).subscribe(
        (e: any) => {
          if (e.code === 200) {
            this.loading = false;
            this.notify.success('新增成功', '');
            this.isRefresh.emit(true);
            this.close();
          }
        },
        () => (this.loading = false),
      );
    } else {
      this.http.post(this.api.IDENTITY_EDIT(this.id), par).subscribe(
        (e: any) => {
          if (e.code === 200) {
            this.loading = false;
            this.notify.success('编辑成功', '');
            this.isRefresh.emit(true);
            this.close();
          }
        },
        () => (this.loading = false),
      );
    }
  }

  // 添加多个
  onBatchAdd(ev: any, type: any): void {
    // type:1-处理器 2-拦截器
    // switch (type) {
    //   case 1:
    //     const allowedAddresses = this.hostForm.value.allowedAddress;
    //     this.allowedAddresses.push(allowedAddresses);
    //     this.hostForm.patchValue({ allowedAddress: null, allowedAddresses: this.allowedAddresses });
    //     break;
    //   case 2:
    //     const address = this.interceptForm.value.address;
    //     this.addresses.push(address);
    //     this.interceptForm.patchValue({ address: null, addresses: this.addresses });
    //     break;
    //   default:
    //     break;
    // }
  }

  onDeleteMore(index: any, type: any): void {
    // type:1-处理器 2-拦截器
    switch (type) {
      case 1:
      //   this.allowedAddresses = this.allowedAddresses.filter((x, i) => i !== index);
      //   this.hostForm.patchValue({ allowedAddresses: this.allowedAddresses });
      //   break;
      // case 2:
      //   this.addresses = this.addresses.filter((x, i) => i !== index);
      //   this.interceptForm.patchValue({ addresses: this.addresses });
      //   break;
      default:
        break;
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { API } from '@service/api';
import { IdentityTypeEnum } from '../util';

@Component({
  templateUrl: './posture.component.html',
  styleUrls: ['./posture.scss'],
})
export class PostureComponent implements OnInit {
  constructor(modalSrv: NzModalService, private api: API, public http: _HttpClient, public notify: NzNotificationService) {
    modalSrv.closeAll();
  }

  ngOnInit(): void {}
}

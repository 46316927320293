import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-uploader-import',
  templateUrl: './uploader-import.component.html',
  styleUrls: ['./uploader.scss'],
})
export class UploaderImportComponent {
  disabled: any;
  @Input()
  public size: 'small' | 'default' | 'large' = 'default';
  @Input()
  public readonly = false;
  @Input()
  public importUrl: any = '';
  @Input()
  public token: any;

  @Output()
  public importSuccessful: EventEmitter<any> = new EventEmitter();
  @Output()
  public importProgress: EventEmitter<any> = new EventEmitter();
  @Output()
  public importError: EventEmitter<any> = new EventEmitter();

  constructor(public http: HttpClient) {}

  handleUpload = (item: any): any => {
    // console.log('try upload: ', item, this.importUrl, this.token);
    const formData = new FormData();
    formData.append('file', item.file as any);
    // tslint:disable-next-line:no-any
    // formData.append('id', '1000');
    const req = new HttpRequest('POST', this.importUrl, formData, {
      headers: new HttpHeaders({ Authorization: this.token }),
      reportProgress: true,
      withCredentials: false,
    });
    // console.log('request: ', req);
    // Always return a `Subscription` object, nz-upload will automatically unsubscribe at the appropriate time
    return this.http.request(req).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            this.importProgress?.emit(event);
            (event as any).percent = (event.loaded / event.total) * 100;
          }
          item.onProgress(event, item.file);
        } else if (event instanceof HttpResponse) {
          this.importSuccessful?.emit(event.body);
          item.onSuccess(event.body, item.file, event);
        }
        // console.log('event: ', event);
      },
      (err: HttpErrorResponse) => {
        // console.log('on error: ', err);
        this.importError?.emit(err);
        item.onError(err, item.file);
      },
    );
  };
}

import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzUploaderFile } from './uploader.entity';
import { HttpClient } from '@angular/common/http';
import { getBase64 } from './uploader.util';
import Viewer from 'viewerjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-uploader-file',
  templateUrl: './uploader-file.component.html',
  styleUrls: ['./uploader.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploaderFileComponent),
      multi: true,
    },
  ],
})
export class UploaderFileComponent implements ControlValueAccessor, OnChanges {
  disabled: any;
  @Input()
  public pdfUrl = '';
  @Input()
  public format: 'full' | 'path' | 'strip' = 'full';
  @Input()
  public uploadUrl = '';
  @Input()
  public downloadUrl = '';
  @Input()
  public token: any;
  @Input()
  public readonly = false;
  // 单位M
  @Input()
  public fileSize: number;
  @Input()
  type: 'file' | 'image' = 'file';
  public files: any = [];
  public results: { [key: string]: NzUploaderFile } = {};
  @Input()
  public multiple = true;

  @Input()
  public max = 99;
  @Input()
  public title = '上传附件';
  @Input()
  public showUploadButton = true;
  @Output()
  public changed: EventEmitter<any> = new EventEmitter();

  showButton = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    showDownloadIcon: true,
  };

  constructor(public http: HttpClient, public notify: NzNotificationService) {}

  private onTouchedCallback = () => {};
  private onChangeCallback = (_: any) => {};

  registerOnChange(fn: any): void {
    if (fn) {
      this.onChangeCallback = fn;
    }
  }

  registerOnTouched(fn: any): void {
    if (fn) {
      this.onTouchedCallback = fn;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  get value(): any {
    return this.data;
  }

  set value(v: any) {
    const files = [];
    const results: any = {};
    if (v) {
      const prefix = new Date().getTime();
      if (v instanceof Array) {
        v.forEach((item, idx) => {
          if (!!item.path) {
            results[prefix + '-' + idx] = { ...item, success: true };
            files.push({ ...item, uid: prefix + '-' + 0, url: this.loadUrl(item.path), success: true });
          }
        });
      } else {
        if (!!v.path) {
          results[prefix + '-' + 0] = { ...v, success: true };
          files.push({ ...v, uid: prefix + '-' + 0, url: this.loadUrl(v.path), success: true });
        } else if (typeof v === 'object') {
          Object.keys(v).forEach((iv: any, idx: any) => {
            if (!!v[iv].path) {
              results[prefix + '-' + idx] = { ...v[iv], success: true };
              files.push({ ...v[iv], uid: prefix + '-' + 0, url: this.loadUrl(v[iv].path), success: true });
            }
          });
        } else {
          const idx = v.lastIndexOf('/');
          results[prefix + '-' + idx] = { path: v, success: true };
          files.push({ name: v.substr(idx ? idx : 0), uid: prefix + '-' + 0, url: this.loadUrl(v), success: true });
        }
      }
    }
    this.results = results;
    this.files = files;
    const out = this.data;
    this.changed.emit(out);
    this.onChangeCallback(out);
  }

  loadUrl(s: any): string {
    if (s.startsWith('http://') || s.startsWith('https://')) {
      return s;
    }
    return this.downloadUrl + s + (this.token ? '?token=' + this.token : '');
  }

  fileChanged(e: any): void {
    switch (e.type) {
      case 'success': {
        const data = e.file.response.data;
        const actualFileSize = data?.size || 0;
        this.results[e.file.uid] = { ...data, success: true };
        // console.log(this.results, data, data.size, actualFileSize, actualFileSize / 1024 / 1024);
        const ss = Object.keys(this.results).map((x) => {
          return { uid: x, url: this.loadUrl(this.results[x].path), ...this.results[x] };
        });
        this.files = ss;
        if (this.fileSize && actualFileSize && actualFileSize / 1024 / 1024 > this.fileSize) {
          this.notify.error('文件过大，请重新上传！', '');
          e.type = 'removed';
          this.fileChanged(e);
        }
        break;
      }
      case 'removed': {
        delete this.results[e.file.uid];
        this.files = this.files.filter((x: any) => x.uid !== e.file.uid);
        break;
      }
      case 'error': {
        this.results[e.file.uid] = { success: false, name: e.file.name };
        break;
      }
      default:
        return;
    }
    // console.log(e);
    this.changed.emit(this.data);
    this.onChangeCallback(this.data);
  }

  get data(): any {
    // console.log(this.results);
    const res = Object.keys(this.results)
      .filter((x) => this.results[x].success)
      .map((x) => this.results[x]);
    switch (this.format) {
      case 'path': {
        return res.map((x) => x.path).join(',');
      }
      case 'strip': {
        return res.length > 0 ? res[0] : null;
      }
      default: {
        return res;
      }
    }
  }

  allowUpload(): boolean {
    return !this.readonly && !this.disabled && (this.multiple ? this.files.length < this.max : this.files.length < 1);
  }

  clear(): void {
    this.files = [];
    this.results = {};
  }

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }

  previewImage: string | undefined = '';
  previewVisible = false;

  timer: any;

  handlePreview = async (file: any) => {
    // console.log(file);
    if (
      file.name?.indexOf('.jpeg') !== -1 ||
      file.name?.indexOf('.jpg') !== -1 ||
      file.name?.indexOf('.png') !== -1 ||
      file.name?.indexOf('.svg') !== -1
    ) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj!);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
      // console.log(this.previewImage);
      if (this.previewImage) {
        this.timer = setTimeout(() => {
          this.getImgInit();
        }, 200);
      }
    } else if (file.name?.indexOf('.pdf') > -1 && this.pdfUrl) {
      const fileUrl = encodeURIComponent(file.url);
      const pdfUrl = this.pdfUrl + fileUrl;
      const windowObjectReference = window.open();
      // @ts-ignore
      windowObjectReference.location.href = pdfUrl;
    } else {
      this.openInNewTab(file.url);
    }
  };

  openInNewTab(url: any): void {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: url,
    }).click();
  }

  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).forEach((chx) => {
      switch (chx) {
        case 'readonly': {
          this.showButton = {
            showPreviewIcon: true,
            showDownloadIcon: true,
            showRemoveIcon: !changes[chx].currentValue && !this.disabled,
          };
          break;
        }
        case 'disabled': {
          this.showButton = {
            showPreviewIcon: true,
            showDownloadIcon: true,
            showRemoveIcon: !changes[chx].currentValue && !this.readonly,
          };
          break;
        }
      }
    });
  }

  modalWidth = 600;

  // 获取图片
  getImgInit(): void {
    const domImg = document.getElementById('dowebok');
    if (domImg) {
      // tslint:disable-next-line:no-unused-expression
      new Viewer(domImg, {
        inline: false,
        button: false,
        navbar: false,
        title: false,
      });
    }
  }
}

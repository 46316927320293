import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { IdentityRoutingModule } from './identity-routing.module';
import { IdentityComponent } from './identity.component';
import { IdentityEditComponent } from './identity-edit.component';
import { UploaderFileModule } from '../common/uploader-file/uploader.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { PostureComponent } from './posture.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { PostureEditComponent } from './posture-edit.component';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { TrafficDetailComponent } from './traffic-detail.component';
import { NzDatePickerModule, NzRangePickerComponent } from 'ng-zorro-antd/date-picker';
import { DeviceBatchAddComponent } from './device-batchAdd.component';

const COMPONENTS = [
  IdentityComponent,
  IdentityEditComponent,
  PostureComponent,
  PostureEditComponent,
  DeviceBatchAddComponent,
  TrafficDetailComponent,
];

@NgModule({
  imports: [
    SharedModule,
    IdentityRoutingModule,
    UploaderFileModule,
    NzIconModule,
    NzSwitchModule,
    NzPaginationModule,
    NzDividerModule,
    NzTagModule,
    NzBadgeModule,
    NzDatePickerModule,
  ],
  declarations: [...COMPONENTS],
  entryComponents: COMPONENTS,
  exports: [...COMPONENTS],
})
export class IdentityModule {}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { API } from '@service/api';
import { IdentityTypeEnum } from '../util';
import { FormBuilder, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.scss'],
})
export class IdentityComponent implements OnInit {
  @Input() index = 0;
  // 列表数据
  listData: any[] = [];
  // 列表配置
  columns: any[] = [];
  tableLoading = false;
  searchValue = '';
  searchCheckValue = '';
  pageSize = 10;
  pageIndex = 1;
  total = 0;
  isVisible = false;
  id: any;
  info: any;
  isPostureVisible = false;
  isRefreshPosture = false;
  isFlowVisible = false;
  identityTypeEnum: any;
  // 批量新增情报板弹框
  isBatchVisible = false;
  deviceTypeEnum: any = [];
  typeOptions: any = [];
  // 设备状态  1: '在线',2: '离线',3: '报警',4: '禁用', 99: '已删除',
  deviceStatusEnum = { 1: '在线', 2: '离线', 3: '报警', 4: '禁用', 99: '已删除' };
  filterForm: FormGroup;
  roomData: any[] = [];
  constructor(
    modalSrv: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private api: API,
    public http: _HttpClient,
    public notify: NzNotificationService,
  ) {
    modalSrv.closeAll();
    this.filterForm = fb.group({
      serial: [null],
      name: [null],
      roomId: [null],
      deviceType: [null],
      workMode: [null],
    });

    this.identityTypeEnum = IdentityTypeEnum;
  }

  ngOnInit(): void {
    this.getRoomData();
    this.getDeviceTypeList();
    this.index === 0 ? this.refreshList() : (this.isRefreshPosture = true);
  }

  onVisibleChange(ev: any): void {
    this.isVisible = ev;
  }

  onPostureVisibleChange(ev: any): void {
    this.isPostureVisible = ev;
  }

  onRefreshPosture(ev: boolean): void {
    this.isRefreshPosture = true;
  }

  onFlowVisibleChange(ev: any): void {
    this.isFlowVisible = ev;
  }

  onRefreshFlow(ev: boolean): void {
    this.isFlowVisible = true;
  }

  pageChange(ev: any): void {
    this.pageIndex = ev;
    this.refreshList();
  }

  pageSizeChange(ev: any): void {
    this.pageSize = ev;
    this.refreshList();
  }

  refreshList(): void {
    const par = { ...this.filterForm.value, pageSize: this.pageSize, pageNum: this.pageIndex };
    this.tableLoading = true;
    this.http.post(this.api.IDENTITY_LIST, par).subscribe(
      (e: any) => {
        if (e.code === 200) {
          this.total = e.total;
          this.listData = e.data.map(
            (x: {
              statusName: any;
              status: string | number;
              deviceTypeName: any;
              deviceType: string | number;
              isDisable: any;
              policyAllowName: string;
              workMode: string;
              workModeName: string;
            }) => {
              // @ts-ignore
              x.statusName = this.deviceStatusEnum[x.status] ? this.deviceStatusEnum[x.status] : '';
              x.deviceTypeName = this.deviceTypeEnum[x.deviceType] ? this.deviceTypeEnum[x.deviceType] : '';
              x.isDisable ? (x.policyAllowName = '禁用') : (x.policyAllowName = '启用');
              if (x.workMode === '1') {
                x.workModeName = '网关';
              } else if (x.workMode === '2') {
                x.workModeName = '透传';
              }
              return x;
            },
          );
          this.tableLoading = false;
        }
      },
      () => (this.tableLoading = false),
    );
  }
  // 获取机房列表
  getRoomData(): void {
    this.http.get(this.api.GET_ROOM_LIST).subscribe((e) => {
      this.roomData = e.data || [];
    });
  }
  onAdd(): void {
    switch (this.index) {
      case 0:
        this.id = '';
        this.isVisible = true;
        break;
      case 1:
        this.isPostureVisible = true;
        this.isRefreshPosture = false;
        break;
      default:
        break;
    }
  }

  onEdit(data: any): void {
    this.id = data.id;
    this.info = data;
    this.isVisible = true;
  }

  onResetAuth(data: any): void {
    if (data.id) {
      this.http.get(this.api.IDENTITY_RESET(data.id)).subscribe((e: any) => {
        if (e.code === 200) {
          this.notify.success('重置成功！', '');
          this.refreshList();
        }
      });
    }
  }

  onDownloadAuth(data: any): void {
    if (data.id) {
      // window.location.href = this.api.IDENTITY_DOWNLOAD(data.id);
      this.http.get(this.api.IDENTITY_DOWNLOAD(data.id)).subscribe((e: any) => {
        if (e.code === 200) {
          const data = e.data;
          const blob = new Blob([data.jwt]);
          saveAs(blob, data.fileName);
          this.notify.success('下载成功！', '');
        }
      });
    }
  }

  getFlow(data: any): void {
    this.isFlowVisible = true;
    this.id = data?.id;
  }

  onDelete(data: any): void {
    if (data.id) {
      this.http.get(this.api.IDENTITY_DEL(data.id)).subscribe((e: any) => {
        if (e.code === 200) {
          this.notify.success('删除成功！', '');
          this.pageIndex = 1;
          this.refreshList();
        }
      });
    }
  }

  onReset(): void {
    this.filterForm.reset({});
    this.searchValue = '';
    this.pageIndex = 1;
    if (this.index === 0) {
      this.refreshList();
    } else {
      this.searchCheckValue = this.searchValue;
    }
  }

  onSearch(): void {
    this.pageIndex = 1;
    if (this.index === 0) {
      this.refreshList();
    } else {
      this.searchCheckValue = this.searchValue;
    }
  }

  onTagChange(ev: number): void {
    this.searchValue = '';
    this.index = ev;
    if (this.index === 0) {
      this.pageIndex = 1;
      this.refreshList();
    }
  }

  onBatchVisibleChange(ev: any): void {
    this.isBatchVisible = ev;
  }

  batchAdd(): void {
    this.id = '';
    this.isBatchVisible = true;
  }

  getDeviceTypeList(): void {
    this.http.get(this.api.GET_DEVICE_TYPE_LIST).subscribe((e) => {
      this.typeOptions = e.data.map((x: { typeName: any; typeValue: any }) => {
        return { text: x.typeName, value: x.typeValue };
      });
      this.deviceTypeEnum = e.data.reduce(
        (obj: any, item: any) => ({
          ...obj,
          [item.typeValue]: item.typeName,
        }),
        {},
      );
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { API } from '@service/api';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-device-batchAdd',
  templateUrl: './device-batchAdd.component.html',
  styleUrls: ['./identity.scss'],
})
export class DeviceBatchAddComponent implements OnInit {
  @Input() id: any;
  @Input() deviceInfoByList: any;
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() isRefresh = new EventEmitter<boolean>();

  set visible(ev: boolean) {
    this.isVisible = ev;
    this.isVisibleChange.emit(ev);
  }

  get visible(): boolean {
    return (this.isVisible = false);
  }

  // 新增编辑表单
  form;
  title = '批量新增情报板';
  loading = false;
  roomOptions: any[] = [
    // { label: '机房1', value: 1 },
  ];
  deviceOptions: any = [];

  constructor(
    private api: API,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public http: _HttpClient,
    public notify: NzNotificationService,
  ) {
    this.form = fb.group({
      startDeviceId: [null, [Validators.required]],
      roomId: [null, [Validators.required]],
      num: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getRoomList();
    this.getDeviceList();
  }

  getRoomList(): void {
    this.http.get(this.api.GET_ROOM_LIST).subscribe((e) => {
      this.roomOptions = e.data;
    });
  }

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }

  /*
   * 获取设备列表
   */
  getDeviceList(): void {
    this.http.get(this.api.ALL_DEVICE).subscribe((e) => {
      if (e.code === 200) {
        this.deviceOptions = e.data || [];
      }
    });
  }

  submit(): void {
    this.loading = true;
    const par = this.form.value;
    this.http.post(this.api.BATCH_ADD_BOARD, par).subscribe(
      (e) => {
        if (e.code === 200) {
          this.loading = false;
          this.notify.success('新增成功', '');
          this.isRefresh.emit(true);
          this.close();
        }
      },
      () => (this.loading = false),
    );
  }
}

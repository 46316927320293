import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { FormBuilder, Validators } from '@angular/forms';
import { linkStatusEnum, linkStatusOptions } from '../category-enum';
import { API } from '@service/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-link-history',
  templateUrl: './link-history.component.html',
  styleUrls: ['./link.scss'],
})
export class LinkHistoryComponent implements OnInit {
  @Input() linkId: any;
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();

  set visible(ev: boolean) {
    this.isVisible = ev;
    this.isVisibleChange.emit(ev);
  }

  get visible(): boolean {
    return (this.isVisible = false);
  }

  // 列表数据
  listData: any[] = [];
  // 列表配置
  columns: any[] = [];
  // 设置阈值
  isThresholdVisible = false;
  thresholdForm;
  loading = false;
  // 编辑
  isEditVisible = false;
  editForm;
  linkStatusOptions: any;
  pageSize = 10;
  pageIndex = 1;
  total = 0;
  tableLoading = false;

  constructor(
    private api: API,
    private fb: FormBuilder,
    public http: _HttpClient,
    modalSrv: NzModalService,
    public notify: NzNotificationService,
  ) {
    this.editForm = fb.group({
      id: [null],
      reportTime: [null],
      curValue: [null],
      status: [null],
    });

    this.thresholdForm = fb.group({
      id: [null],
      isOpen: [null],
      threshold: [null],
    });
  }

  /**
   * 初始化
   */
  ngOnInit(): void {
    this.linkStatusOptions = linkStatusOptions;
    // this.initColumns();
    this.refreshListData();
  }

  editSubmit(): void {
    const par = this.editForm.value;
    this.http.post(this.api.EDIT_DATA, par).subscribe(
      (e) => {
        if (e.code === 200) {
          this.loading = this.isEditVisible = false;
          this.notify.success('编辑成功', '');
          this.refreshListData();
        }
      },
      () => (this.loading = false),
    );
  }

  /**
   * 初始化列表配置
   */
  // initColumns(): void {
  //   this.columns = [
  //     {
  //       title: '时间', index: 'reportTime', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', filter: {
  //         type: 'datetime', fn: (filter, record) => {
  //           if (filter.value != null && filter.value.length === 2) {
  //             const startTime = filter.value[0];
  //             const endTime = filter.value[1];
  //             const nowTime = new Date(record.reportTime);
  //             return nowTime.getTime() >= startTime.getTime() && nowTime.getTime() <= endTime.getTime();
  //           }
  //           return true;
  //         },
  //       },
  //     },
  //     {
  //       title: '实时流量', index: 'curValue', filter: {
  //         type: 'keyword',
  //         fn: (filter, record) => !filter.value || (record.curValue && record.curValue.indexOf(filter.value) !== -1),
  //       },
  //     },
  //     {
  //       title: '状态', index: 'statusName', filter: {
  //         menus: [
  //           { text: '正常', value: '正常' },
  //           { text: '断开', value: '断开' },
  //           { text: '报警', value: '报警' },
  //           { text: '禁用', value: '禁用' }],
  //         fn: (filter, record) => !filter.value || (record.statusName && record.statusName.indexOf(filter.value) !== -1),
  //         multiple: false,
  //       },
  //     },
  //     {
  //       title: '操作',
  //       buttons: [
  //         {
  //           text: '编辑',
  //           type: 'none',
  //           click: (e: any) => {
  //             e.reportTime = e.createdTime;
  //             // e.curValue1 = e.curValue;
  //             // e.status1 = e.status;
  //             this.editForm.reset(e);
  //             this.isEditVisible = true;
  //           },
  //         },
  //         {
  //           text: '删除',
  //           type: 'none',
  //           pop: '是否确认删除？',
  //           click: (e: any) => this.deleteData(e.id),
  //         },
  //       ],
  //     },
  //   ];
  // }

  submitThreshold(): void {
    const par = this.thresholdForm.value;
    par.id = this.linkId;
    this.http.post(this.api.SET_THRESHOLD, par).subscribe(
      (e) => {
        if (e.code === 200) {
          this.loading = this.isThresholdVisible = false;
          this.notify.success('设置成功', '');
          this.refreshListData();
        }
      },
      () => (this.loading = false),
    );
  }

  onEdit(e: any): void {
    e.reportTime = e.createdTime;
    this.editForm.reset(e);
    this.isEditVisible = true;
  }

  deleteData(id: any): void {
    this.http.delete(this.api.DELETE_DATA(id)).subscribe((e) => {
      if (e.code === 200) {
        this.notify.success('删除成功', '');
        this.refreshListData();
      }
    });
  }

  /**
   * 刷新列表数据
   */
  refreshListData(): void {
    const par = { pageSize: this.pageSize, pageNum: this.pageIndex };
    this.tableLoading = true;
    this.http.get(this.api.VIEW_DATA_LIST(this.linkId), par).subscribe(
      (e) => {
        if (e.code === 200) {
          this.listData = e.data.map((x: { statusName: any; status: string | number }) => {
            // @ts-ignore
            x.statusName = linkStatusEnum[x.status];
            return x;
          });
          this.total = e.total;
          this.tableLoading = false;
        }
      },
      () => (this.tableLoading = false),
    );
  }

  onSet(): void {
    this.thresholdForm.reset({ isOpen: true });
    this.isThresholdVisible = true;
  }

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }

  pageChange(ev: any): void {
    this.pageIndex = ev;
    this.refreshListData();
  }

  pageSizeChange(ev: any): void {
    this.pageSize = ev;
    this.refreshListData();
  }
}

<!--查看流量明细-->
<nz-modal *ngIf="isVisible" [(nzVisible)]="isVisible" [nzTitle]="titleTemplateRef" nzClosable="false" nzWidth="70%">
  <ng-template #titleTemplateRef>
    <div style="display: flex; justify-content: space-between">
      <div>流量明细</div>
      <div>
        <!--        <button nz-button nzType="default" (click)="close()">阻断</button>-->
        <button nz-button nzType="default" (click)="close()">关闭</button>
      </div>
    </div>
  </ng-template>

  <ng-container *nzModalContent>
    <div class="list-search-part" nz-form [formGroup]="form">
      <div class="form-row">
        <div class="form-col-3">
          <nz-form-item>
            <nz-form-label>选择时间段</nz-form-label>
            <nz-form-control>
              <nz-range-picker
                style="width: 100%"
                placeholder="请选择"
                formControlName="dateRange"
                [nzShowTime]="{ nzFormat: 'HH:mm' }"
                nzFormat="yyyy-MM-dd HH:mm"
              ></nz-range-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="form-col-3">
          <!--          <nz-form-item>-->
          <!--            <nz-form-label [nzSm]="5" [nzXs]="24">选择端口</nz-form-label>-->
          <!--            <nz-form-control>-->
          <!--              <input nz-input formControlName="port" placeholder="请输入" />-->
          <!--            </nz-form-control>-->
          <!--          </nz-form-item>-->
        </div>
        <div class="form-col-3">
          <div style="text-align: right">
            <button nz-button nzType="primary" (click)="onSearch()">查询</button>
            <button nz-button nzType="default" (click)="onReset()">重置</button>
          </div>
        </div>
      </div>
    </div>

    <nz-table nzSize="middle" [nzFrontPagination]="false" [nzData]="listData" [nzLoading]="tableLoading">
      <thead>
        <tr>
          <th>时间</th>
          <th>上行流量</th>
          <th>下行流量</th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr *ngFor="let data of listData; let i = index">
            <td>{{ data?.createdTime | date : 'yyyy-MM-dd HH:mm:ss' }}</td>
            <td>{{ data?.curValue1 ? data?.curValue1 + 'kb' : '' }}</td>
            <td>{{ data?.curValue2 ? data?.curValue2 + 'kb' : '' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>

    <div class="table-pagination-box">
      <nz-pagination
        [nzPageIndex]="pageIndex"
        [nzTotal]="total"
        nzShowSizeChanger
        [nzPageSize]="pageSize"
        (nzPageIndexChange)="pageChange($event)"
        (nzPageSizeChange)="pageSizeChange($event)"
        [nzShowTotal]="totalTemplate"
      >
        <ng-template #totalTemplate let-total>共{{ total }}条</ng-template>
      </nz-pagination>
    </div>
  </ng-container>

  <div *nzModalFooter></div>
</nz-modal>

<!--新增编辑数据-->
<nz-modal *ngIf="isVisible" [(nzVisible)]="isVisible" [nzTitle]="titleTemplateRef" nzClosable="false" nzWidth="700">
  <ng-template #titleTemplateRef>
    <div style="display: flex; justify-content: space-between">
      <div>查看链路历史</div>
      <div>
        <button nz-button nzType="primary" (click)="onSet()">设置报警阈值</button>
        <button nz-button nzType="default" (click)="close()">关闭</button>
      </div>
    </div>
  </ng-template>
  <ng-container *nzModalContent>
    <!--    <ng-table #st [data]='listData' [columns]='columns'></ng-table>-->
    <nz-table nzSize="middle" [nzFrontPagination]="false" [nzData]="listData" [nzLoading]="tableLoading">
      <thead>
        <tr>
          <th>时间</th>
          <th>实时流量</th>
          <th>状态</th>
          <th nzWidth="250px" nzRight>操作</th>
        </tr>
      </thead>
      <tbody>
        <ng-container>
          <tr *ngFor="let data of listData; let i = index">
            <!--<td>{{ (pageIndex - 1) * pageSize + i + 1 }}</td>-->

            <td>{{ data?.reportTime | date : 'yyyy-MM-dd HH:mm:ss' }} }}</td>
            <td>{{ data?.curValue }}</td>
            <td>{{ data?.statusName }}</td>

            <!--        <td>{{ data?.type ? identityTypeEnum[data?.type] : '' }}</td>-->
            <td nzWidth="250px" nzRight>
              <a (click)="onEdit(data)">编辑</a>
              <nz-divider nzType="vertical"></nz-divider>
              <a nz-popconfirm nzPopconfirmTitle="确定要删除该数据吗?" (nzOnConfirm)="deleteData(data?.id)">删除</a>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
    <div class="table-pagination-box">
      <nz-pagination
        [nzPageIndex]="pageIndex"
        [nzTotal]="total"
        nzShowSizeChanger
        [nzPageSize]="pageSize"
        (nzPageIndexChange)="pageChange($event)"
        (nzPageSizeChange)="pageSizeChange($event)"
        [nzShowTotal]="totalTemplate"
      >
        <ng-template #totalTemplate let-total>共{{ total }}条</ng-template>
      </nz-pagination>
    </div>
  </ng-container>
  <div *nzModalFooter></div>
</nz-modal>

<!--设置阈值-->
<nz-modal *ngIf="isThresholdVisible" [(nzVisible)]="isThresholdVisible" nzTitle="设置阈值" nzClosable="false">
  <ng-container *nzModalContent>
    <form nz-form [formGroup]="thresholdForm">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="isOpen">是否开启</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <nz-switch formControlName="isOpen" nzCheckedChildren="开" nzUnCheckedChildren="关"></nz-switch>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="threshold">阈值</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <input nz-input formControlName="threshold" placeholder="请输入" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
  <div *nzModalFooter>
    <div style="margin-top: -5px">
      <button nz-button nzType="default" (click)="isThresholdVisible = false">取消</button>
      <button nz-button nzType="primary" [nzLoading]="loading" (click)="submitThreshold()" [disabled]="thresholdForm.invalid">确定</button>
    </div>
  </div>
</nz-modal>

<!--编辑数据-->
<nz-modal *ngIf="isEditVisible" [(nzVisible)]="isEditVisible" nzTitle="编辑数据" nzClosable="false">
  <ng-container *nzModalContent>
    <form nz-form [formGroup]="editForm">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="reportTime">时间</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <nz-date-picker nzShowTime formControlName="reportTime"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="curValue">值</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <input nz-input formControlName="curValue" placeholder="请输入" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="status">状态</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <nz-select name="select-validate" nzPlaceHolder="请选择" formControlName="status">
            <nz-option *ngFor="let item of linkStatusOptions" [nzValue]="item.value" [nzLabel]="item.name"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>

  <div *nzModalFooter>
    <div style="margin-top: -5px">
      <button nz-button nzType="default" (click)="isEditVisible = false">取消</button>
      <button nz-button nzType="primary" [nzLoading]="loading" (click)="editSubmit()" [disabled]="editForm.invalid">确定</button>
    </div>
  </div>
</nz-modal>

<div class="alain-default__content-title">
  <div class="page-top-title"><span class="title-level-one">系统设置</span>/ <span class="title-level-two">IP地址管理</span></div>
</div>
<ng-template #rightTemplate>
  <button nz-button nzType="primary" (click)="onAdd()">新增</button>
</ng-template>
<nz-card style="width: 100%" [nzBordered]="false" nzTitle="IP地址列表" class="indicator-card" [nzExtra]="rightTemplate">
  <nz-table nzSize="middle" [nzFrontPagination]="false" [nzData]="listData" [nzLoading]="tableLoading" [nzScroll]="{ x: '1400px' }">
    <thead>
      <tr>
        <th nzWidth="120px">序号</th>
        <th>地址</th>
        <th>IP</th>
        <th>创建时间</th>
        <th nzWidth="150px" nzRight>操作</th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr *ngFor="let data of listData; let i = index">
          <td nzWidth="120px">{{ (pageIndex - 1) * pageSize + i + 1 }}</td>
          <td>{{ data?.addressName }}</td>
          <td>
            <nz-tag nzColor="#55acee" *ngFor="let item of data.details">
              <span *ngIf="item.ipType == 1"> {{ item.ip }} <br /></span>
              <span *ngIf="item.ipType == 2"> {{ item.ipStart }} ~ {{ item.ipEnd }}<br /></span>
            </nz-tag>
          </td>

          <td>{{ data.createdTime | date : 'yyyy-MM-dd HH:mm' }}</td>
          <td nzWidth="150px" nzRight>
            <a (click)="onEdit(data)">编辑</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a nz-popconfirm nzPopconfirmTitle="确定要删除该数据吗?" (nzOnConfirm)="onDelete(data.id)">删除</a>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
  <div class="table-pagination-box">
    <nz-pagination
      [nzPageIndex]="pageIndex"
      [nzTotal]="total"
      nzShowSizeChanger
      [nzPageSize]="pageSize"
      (nzPageIndexChange)="pageChange($event)"
      (nzPageSizeChange)="pageSizeChange($event)"
      [nzShowTotal]="totalTemplate"
    >
      <ng-template #totalTemplate let-total>共{{ total }}条</ng-template>
    </nz-pagination>
  </div>
</nz-card>

<!--新增编辑配置-->
<app-config-edit
  *ngIf="isVisible"
  [isVisible]="isVisible"
  [id]="id"
  (isVisibleChange)="onVisibleChange($event)"
  (isRefresh)="refreshListData()"
>
</app-config-edit>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';
import { Exception404Component } from '@showye/showye-page-admin/layout-admin';
import { ShowyePageAuthGuard } from '@showye/showye-page-base';
import { LayoutComponent } from '../layout/layout.component';
import { IdentityModule } from './identity/identity.module';
import { PostureModule } from './posture/posture.module';
import { LinkModule } from './link/link.module';
import { IpAddressModule } from './ip-address/ip-address.module';

const routes: Routes = [
  { path: 'passport', loadChildren: () => import('./login/passport.module').then((m) => m.PassportModule) },
  {
    path: '',
    canActivate: [ShowyePageAuthGuard],
    canActivateChild: [ShowyePageAuthGuard],
    children: [
      { path: '', redirectTo: '/safe/identity', pathMatch: 'full' },
      { path: 'view', loadChildren: () => import('./view/view.module').then((m) => m.ViewModule) },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ShowyePageAuthGuard],
    canActivateChild: [ShowyePageAuthGuard],
    children: [
      { path: 'exception', children: [{ path: '404', component: Exception404Component }] },
      // //  在下面放置 业务模块 的内容
      // { path: '', children: [{ path: '', redirectTo: '/safe/identity', pathMatch: 'full' }] },
      // 情报板安全管理平台
      // 策略管理
      {
        path: 'safe',
        children: [
          { path: '', redirectTo: '/safe/identity', pathMatch: 'full' },
          { path: 'identity', loadChildren: () => import('./identity/identity.module').then((m) => IdentityModule) },
          { path: 'link', loadChildren: () => import('./link/link.module').then((m) => LinkModule) },
          { path: 'address', loadChildren: () => import('./ip-address/ip-address.module').then((m) => IpAddressModule) },
          { path: 'posture', loadChildren: () => import('./posture/posture.module').then((m) => PostureModule) },
          { path: 'policy', loadChildren: () => import('./policy/policy.module').then((m) => m.PolicyModule) },
          { path: 'authPolicy', loadChildren: () => import('./auth-policy/auth.module').then((m) => m.AuthModule) },
          { path: 'jwtPolicy', loadChildren: () => import('./jwt-policy/jwt.module').then((m) => m.JwtModule) },
          { path: 'config', loadChildren: () => import('./config/config.module').then((m) => m.ConfigModule) },
          { path: 'service', loadChildren: () => import('./service/service.module').then((m) => m.ServiceModule) },
          { path: 'cert', loadChildren: () => import('./cert/cert.module').then((m) => m.CertModule) },
          { path: 'caCert', loadChildren: () => import('./caCert/caCert.module').then((m) => m.CaCertModule) },
          { path: 'control', loadChildren: () => import('./control/control.module').then((m) => m.ControlModule) },
          { path: 'random', loadChildren: () => import('./randomConfig/random-config.module').then((m) => m.RandomConfigModule) },
          { path: 'alarm', loadChildren: () => import('./alarm/alarm.module').then((m) => m.AlarmModule) },
          { path: 'machine', loadChildren: () => import('./machine/machine.module').then((m) => m.MachineModule) },
          { path: 'key', loadChildren: () => import('./key/key.module').then((m) => m.KeyModule) },
          { path: 'graph', loadChildren: () => import('./graph/graph.module').then((m) => m.GraphModule) },
        ],
      },
      {
        path: 'set',
        children: [
          { path: '', redirectTo: '/set/domain', pathMatch: 'full' },
          { path: 'menu', loadChildren: () => import('./set/menu/menu.module').then((m) => m.MenuModule) },
          { path: 'code', loadChildren: () => import('./set/code/code.module').then((m) => m.CodeModule) },
          { path: 'domain', loadChildren: () => import('./set/domain/domain.module').then((m) => m.DomainModule) },
          { path: 'role', loadChildren: () => import('./set/role/role.module').then((m) => m.RoleModule) },
          { path: 'user', loadChildren: () => import('./set/user/user.module').then((m) => m.UserModule) },
          { path: 'view', loadChildren: () => import('./set/view/view.module').then((m) => m.ViewModule) },
          { path: 'log', loadChildren: () => import('./set/log/log.module').then((m) => m.LogModule) },
          // 我的资料
          // { path: 'profile', loadChildren: () => import('./my/profile/profile.module').then((m) => m.ProfileModule) },
        ],
      },
    ],
  },
  { path: '**', redirectTo: 'exception/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule {}

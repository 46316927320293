import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { UploaderFileComponent } from './uploader-file.component';
import { UploaderListComponent } from './uploader-list.component';
import { UploaderImportComponent } from './uploader-import.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';

const COMPONENTS = [UploaderImportComponent, UploaderFileComponent, UploaderListComponent];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzUploadModule,
    NzToolTipModule,
    NzAvatarModule,
    NzModalModule,
    NzIconModule,
    NzButtonModule,
  ],
  declarations: [...COMPONENTS],
  providers: [],
  exports: [...COMPONENTS],
})
export class UploaderFileModule {}

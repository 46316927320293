import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { IpAddressRoutingModule } from './ip-address-routing.module';
import { IpAddressComponent } from './ip-address.component';
import { IpAddressEditComponent } from './ip-address-edit.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';

const COMPONENTS = [IpAddressComponent, IpAddressEditComponent];

@NgModule({
  imports: [SharedModule, IpAddressRoutingModule, NzTagModule, NzDividerModule, NzPaginationModule],
  declarations: [...COMPONENTS],
  entryComponents: COMPONENTS,
  exports: [...COMPONENTS],
})
export class IpAddressModule {}

import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { UploaderFileModule } from '../common/uploader-file/uploader.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { PostureRoutingModule } from './posture-routing.module';
import { PostureComponent } from './posture.component';
import { IdentityModule } from '../identity/identity.module';

const COMPONENTS = [PostureComponent];

@NgModule({
  imports: [
    SharedModule,
    PostureRoutingModule,
    UploaderFileModule,
    NzIconModule,
    NzSwitchModule,
    NzPaginationModule,
    NzDividerModule,
    NzTagModule,
    NzBadgeModule,
    IdentityModule,
  ],
  declarations: [...COMPONENTS],
  entryComponents: COMPONENTS,
  exports: [...COMPONENTS],
})
export class PostureModule {}

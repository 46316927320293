import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { API } from '@service/api';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-config-edit',
  templateUrl: './ip-address-edit.component.html',
  styleUrls: ['./ip-address.scss'],
})
export class IpAddressEditComponent implements OnInit {
  @Input() id: any;
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() isRefresh = new EventEmitter<boolean>();

  set visible(ev: boolean) {
    this.isVisible = ev;
    this.isVisibleChange.emit(ev);
  }

  get visible(): boolean {
    return (this.isVisible = false);
  }

  info: any;
  // 新增编辑表单
  form;
  hostForm: any;
  interceptForm: any;
  title = '新增IP地址';
  loading = false;
  // 类型
  selectType: any;
  //  IP列表集合
  details: any = [];
  ipTypes: any;

  constructor(
    private api: API,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public http: _HttpClient,
    public notify: NzNotificationService,
  ) {
    this.form = fb.group({
      addressName: [null, [Validators.required, Validators.maxLength(50)]],
    });
  }

  ngOnInit(): void {
    this.id ? (this.title = '编辑IP地址') : (this.title = '新增IP地址');
    this.form.valueChanges.subscribe((data) => {
      data.details = this.details;
    });
    this.getIpTypes();
    if (this.id) {
      this.getInfo(this.id);
    }
  }

  getIpTypes(): void {
    this.ipTypes = [
      { id: '1', name: '指定IP' },
      { id: '2', name: '范围IP' },
    ];
  }

  getInfo(id: any): void {
    this.details = [];
    this.http.get(this.api.IP_ADDRESS_INFO(id)).subscribe((e) => {
      if (e.code === 200) {
        this.info = e.data;
        this.form.reset(this.info);
        this.details = e.data.details;
      }
    });
  }

  onAddIp(): void {
    const detail = { ipType: '', ip: '', ipStart: '', ipEnd: '' };
    this.details = this.details.concat(detail);
  }

  onDelIp(ev: number): void {
    this.details = this.details.filter((x: any, i: number) => i !== ev);
  }

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }

  onTypeChange(ev: any): void {
    this.selectType = ev;
    this.form.patchValue({ configTypeId: ev });
  }

  submit(): any {
    this.loading = true;
    const par = { ...this.form.value };

    par.details = this.details;
    if (!this.id) {
      this.http.post(this.api.ADD_IP_ADDRESS, par).subscribe(
        (e) => {
          if (e.code === 200) {
            this.loading = false;
            this.isRefresh.emit(true);
            this.close();
          }
        },
        () => (this.loading = false),
      );
    } else {
      par.id = this.id;
      this.http.post(this.api.UPDATE_IP_ADDRESS(this.id), par).subscribe(
        (e) => {
          if (e.code === 200) {
            this.loading = false;
            this.isRefresh.emit(true);
            this.close();
          }
        },
        () => (this.loading = false),
      );
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { FormBuilder, Validators } from '@angular/forms';
import { linkStatusEnum } from '../category-enum';
import { API } from '@service/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  templateUrl: './list.component.html',
  styleUrls: ['./link.scss'],
})
export class ListComponent implements OnInit {
  // 列表数据
  listData: any[] = [];
  // 列表配置
  columns: any[] = [];
  tableLoading = false;
  // 新增编辑设备弹框
  isVisible = false;
  // 新增编辑表单
  form;
  id: any;
  title = '新增链路';
  loading = false;
  // 设置阈值
  isThresholdVisible = false;
  thresholdForm;
  deviceOptions: any = [];
  deviceStartPortOptions: any = [];
  deviceEndPortOptions: any = [];
  linkId: any;
  isLinkHistoryVisible = false;
  searchValue = '';
  searchCheckValue = '';
  pageSize = 10;
  pageIndex = 1;
  total = 0;

  constructor(
    private api: API,
    private fb: FormBuilder,
    public http: _HttpClient,
    modalSrv: NzModalService,
    public notify: NzNotificationService,
  ) {
    modalSrv.closeAll();
    this.form = fb.group({
      startDeviceId: [null, [Validators.required]],
      startDevicePort: [null, [Validators.required]],
      endDeviceId: [null, [Validators.required]],
      endDevicePort: [null, [Validators.required]],
    });
    this.thresholdForm = fb.group({
      isOpen: [null],
      threshold: [null],
    });
  }

  /**
   * 初始化
   */
  ngOnInit(): void {
    //  this.initColumns();
    this.getDeviceList();
    this.refreshListData();
  }

  /*
   * 获取设备列表
   */
  getDeviceList(): void {
    this.http.get(this.api.ALL_DEVICE).subscribe((e: any) => {
      if (e.code === 200) {
        this.deviceOptions = e.data || [];
      }
    });
  }

  /**
   * 初始化列表配置
   */
  // initColumns(): void {
  //   this.columns = [
  //     {
  //       title: '链路编号', index: 'linkSerial', filter: {
  //         type: 'keyword',
  //         fn: (filter: any, record: any) => !filter.value || (record.linkSerial && record.linkSerial.indexOf(filter.value) !== -1),
  //       },
  //     },
  //     {
  //       title: '起点设备（编号）', index: 'startDeviceInfo', filter: {
  //         type: 'keyword',
  //         fn: (filter: any, record: any) => !filter.value || (record.startDeviceInfo && record.startDeviceInfo.indexOf(filter.value) !== -1),
  //       },
  //     },
  //     {
  //       title: '起点端口', index: 'startDevicePort', filter: {
  //         type: 'keyword',
  //         fn: (filter: any, record: any) => !filter.value || (record.startDevicePort && record.startDevicePort.indexOf(filter.value) !== -1),
  //       },
  //     },
  //     {
  //       title: '终点设备（编号）', index: 'endDeviceInfo', filter: {
  //         type: 'keyword',
  //         fn: (filter: any, record: any) => !filter.value || (record.endDeviceInfo && record.endDeviceInfo.indexOf(filter.value) !== -1),
  //       },
  //     },
  //     {
  //       title: '终点端口', index: 'endDevicePort', filter: {
  //         type: 'keyword',
  //         fn: (filter: any, record: any) => !filter.value || (record.endDevicePort && record.endDevicePort.indexOf(filter.value) !== -1),
  //       },
  //     },
  //     {
  //       title: '链路状态', index: 'statusName', filter: {
  //         menus: [
  //           { text: '正常', value: '正常' },
  //           { text: '断开', value: '断开' },
  //           { text: '报警', value: '报警' },
  //           { text: '禁用', value: '禁用' }],
  //         fn: (filter: any, record: any) => !filter.value || (record.statusName && record.statusName.indexOf(filter.value) !== -1),
  //         multiple: false,
  //       },
  //     },
  //     // {
  //     //   title: '按小时统计', index: 'viewButton', type: 'link', click: (e: any) => {
  //     //   },
  //     // },
  //     // {
  //     //   title: '按天统计', index: 'viewButton', type: 'link', click: (e: any) => {
  //     //   },
  //     // },
  //     // {
  //     //   title: '按周统计', index: 'viewButton', type: 'link', click: (e: any) => {
  //     //   },
  //     // },
  //     // {
  //     //   title: '按月统计', index: 'viewButton', type: 'link', click: (e: any) => {
  //     //   },
  //     // },
  //     {
  //       title: '操作',
  //       width: 300,
  //       buttons: [
  //         {
  //           text: '编辑',
  //           type: 'none',
  //           click: (e: any) => {
  //             this.title = '编辑链路';
  //             this.form.reset({});
  //             this.linkId = e.id;
  //             this.getPortByDeviceId(e.startDeviceId, true);
  //             this.getPortByDeviceId(e.endDeviceId, false);
  //             setTimeout(() => {
  //               this.form.reset(e);
  //             }, 500);
  //             this.isVisible = true;
  //           },
  //         },
  //         {
  //           text: '链路流量',
  //           type: 'none',
  //           click: (e: any) => {
  //             this.linkId = e.id;
  //             this.isLinkHistoryVisible = true;
  //           },
  //         },
  //         {
  //           text: '禁用',
  //           type: 'none',
  //           pop: '是否确认禁用？',
  //           iif: (e: any) => e.status === '1',
  //           click: (e: any) => this.disableLink(e.id),
  //         },
  //         {
  //           text: '启用',
  //           type: 'none',
  //           pop: '是否确认启用？',
  //           iif: (e: any) => e.status === '4',
  //           click: (e: any) => this.enableLink(e.id),
  //         },
  //         {
  //           text: '删除',
  //           type: 'none',
  //           pop: '是否确认删除？',
  //           click: (e: any) => this.deleteLink(e.id),
  //         },
  //         // {
  //         //   text: '密钥使用量',
  //         //   type: 'none',
  //         //   click: (e: any) => {
  //         //   },
  //         // },
  //       ],
  //     },
  //   ];
  // }

  disableLink(id: any): void {
    this.http.get(this.api.DISABLE_LINK(id)).subscribe((e: any) => {
      if (e.code === 200) {
        this.notify.success('禁用成功', '');
        this.refreshListData();
      }
    });
  }

  enableLink(id: any): void {
    this.http.get(this.api.ENABLE_LINK(id)).subscribe((e: any) => {
      if (e.code === 200) {
        this.notify.success('启用成功', '');
        this.refreshListData();
      }
    });
  }

  deleteLink(id: any): void {
    this.http.delete(this.api.DELETE_LINK(id)).subscribe((e: any) => {
      if (e.code === 200) {
        this.notify.success('删除成功', '');
        this.refreshListData();
      }
    });
  }

  onStartChange(ev: any): void {
    if (ev) {
      this.getPortByDeviceId(ev, true);
      this.form.patchValue({ startDevicePort: null });
    }
  }

  onEndChange(ev: any): void {
    if (ev) {
      this.getPortByDeviceId(ev, false);
      this.form.patchValue({ endDevicePort: null });
    }
  }

  /**
   * 根据设备id查询设备接口
   */
  getPortByDeviceId(id: any, isStart: any): void {
    this.http.get(this.api.GET_PORT_BY_DEVICE_ID(id)).subscribe((e: any) => {
      if (e.code === 200) {
        isStart ? (this.deviceStartPortOptions = e.data) : (this.deviceEndPortOptions = e.data);
      }
    });
  }

  /**
   * 刷新列表数据
   */
  refreshListData(): void {
    const par = { name: this.searchValue, pageSize: this.pageSize, pageNum: this.pageIndex };
    this.tableLoading = true;
    this.http.get(this.api.GET_LINK_LIST, par).subscribe(
      (e: any) => {
        if (e.code === 200) {
          this.listData = e.data.map(
            (x: {
              viewButton: string;
              startDeviceInfo: string;
              startDeviceName: string;
              startDeviceSerial: string;
              endDeviceInfo: string;
              endDeviceName: string;
              endDeviceSerial: string;
              statusName: any;
              status: string | number;
            }) => {
              x.viewButton = '查看';
              x.startDeviceInfo = x.startDeviceName + '（' + x.startDeviceSerial + '）';
              x.endDeviceInfo = x.endDeviceName + '（' + x.endDeviceSerial + '）';
              // @ts-ignore
              x.statusName = linkStatusEnum[x.status];
              return x;
            },
          );
          this.total = e.total;
          this.tableLoading = false;
        }
      },
      () => (this.tableLoading = false),
    );
  }

  onAdd(): void {
    this.form.reset({});
    this.linkId = '';
    this.title = '新增链路';
    this.isVisible = true;
  }

  submit(): void {
    const par = this.form.value;
    if (!this.linkId) {
      this.http.post(this.api.ADD_LINK, par).subscribe(
        (e: any) => {
          if (e.code === 200) {
            this.loading = this.isVisible = false;
            this.refreshListData();
          }
        },
        () => (this.loading = false),
      );
    } else {
      par.id = this.linkId;
      this.http.post(this.api.EDIT_LINK, par).subscribe(
        (e: any) => {
          if (e.code === 200) {
            this.loading = this.isVisible = false;
            this.refreshListData();
          }
        },
        () => (this.loading = false),
      );
    }
  }

  onSetThreshold(): void {
    this.thresholdForm.reset({ isOpen: true });
    this.isThresholdVisible = true;
  }

  closeThreshold(): void {
    this.isThresholdVisible = false;
  }

  submitThreshold(): void {
    const par = this.thresholdForm.value;
    this.http.post(this.api.SET_ALL_THRESHOLD, par).subscribe(
      (e: any) => {
        if (e.code === 200) {
          this.loading = this.isThresholdVisible = false;
          this.refreshListData();
        }
      },
      () => (this.loading = false),
    );
  }

  isVisibleChange(ev: any): void {
    this.isLinkHistoryVisible = ev;
  }

  pageChange(ev: any): void {
    this.pageIndex = ev;
    this.refreshListData();
  }

  pageSizeChange(ev: any): void {
    this.pageSize = ev;
    this.refreshListData();
  }

  onEdit(e: any): void {
    this.title = '编辑链路';
    this.linkId = e.id;
    this.getPortByDeviceId(e.startDeviceId, true);
    this.getPortByDeviceId(e.endDeviceId, false);
    this.isVisible = true;
    setTimeout(() => {
      this.form.reset(e);
    }, 500);
  }

  onLinkHistory(e: any): void {
    this.linkId = e.id;
    this.isLinkHistoryVisible = true;
  }

  onStatus(e: any): void {
    this.disableLink(e.id);
    this.enableLink(e.id);
  }

  onDelete(e: any): void {
    this.deleteLink(e.id);
  }
}

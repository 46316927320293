import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { API } from '@service/api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  templateUrl: './ip-address.component.html',
  styleUrls: ['./ip-address.scss'],
})
export class IpAddressComponent implements OnInit {
  // 列表数据
  listData: any[] = [];
  // 列表配置
  columns: any[] = [];
  isVisible = false;
  id: any;
  configTypeEnum = {
    // NH5p4FpGR: '处理器',
    // g7cIWbcGg: '拦截器'
  };
  tableLoading = false;
  pageSize = 10;
  pageIndex = 1;
  total = 0;

  constructor(
    private api: API,
    modalSrv: NzModalService,
    private router: Router,
    private route: ActivatedRoute,
    public http: _HttpClient,
    public notify: NzNotificationService,
    private fb: FormBuilder,
  ) {
    modalSrv.closeAll();
  }

  ngOnInit(): void {
    // this.initColumns();
    this.refreshListData();
  }

  /**
   * 刷新列表数据
   */
  refreshListData(): void {
    this.tableLoading = true;
    const par = { pageSize: this.pageSize, pageNum: this.pageIndex };
    this.http.post(this.api.IP_ADDRESS_LIST, par).subscribe(
      (e) => {
        if (e.code === 200) {
          this.tableLoading = false;
          const data = e.data || [];
          this.tableLoading = false;
          this.total = e.total;
          this.listData = data;
        }
      },
      () => (this.tableLoading = false),
    );
  }

  onVisibleChange(ev: any): void {
    this.isVisible = ev;
  }

  onAdd(): void {
    this.id = '';
    this.isVisible = true;
  }

  /**
   * 初始化列表配置
   */
  // initColumns(): void {
  //   this.columns = [
  //     {
  //       title: '序号', index: 'No',
  //       width: 150, filter: {
  //         type: 'keyword',
  //         fn: (filter, record) => !filter.value || (record.No && record.No.indexOf(filter.value) !== -1),
  //       },
  //     },
  //     {
  //       title: '地址', index: 'addressName', filter: {
  //         type: 'keyword',
  //         fn: (filter, record) => !filter.value || (record.addressName && record.addressName.indexOf(filter.value) !== -1),
  //         // multiple: false,
  //       },
  //     },
  //     {
  //       title: 'IP', index: 'details',
  //     },
  //     {
  //       title: '创建时间', index: 'createdTime', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', filter: {
  //         type: 'datetime', fn: (filter, record) => {
  //           if (filter.value != null && filter.value.length === 2) {
  //             const startTime = filter.value[0];
  //             const endTime = filter.value[1];
  //             const nowTime = new Date(record.createAt);
  //             return nowTime.getTime() >= startTime.getTime() && nowTime.getTime() <= endTime.getTime();
  //           }
  //           return true;
  //         },
  //       },
  //     },
  //     {
  //       title: '操作',
  //       width: 200,
  //       buttons: [
  //         {
  //           text: '编辑',
  //           type: 'none',
  //           click: (e: any) => {
  //             this.id = e.id;
  //             this.isVisible = true;
  //           },
  //         },
  //         {
  //           text: '删除',
  //           type: 'none',
  //           pop: '是否确认删除？',
  //           click: (e: any) => this.onDelete(e.id),
  //         },
  //       ],
  //     },
  //   ];
  // }

  onDelete(id: any): void {
    if (id) {
      this.http.delete(this.api.IP_ADDRESS_DEL(id)).subscribe((e) => {
        if (e.code === 200) {
          this.notify.success('删除成功', '');
          this.refreshListData();
        }
      });
    }
  }

  onEdit(data: any): void {
    this.id = data.id;
    this.isVisible = true;
  }

  pageChange(ev: any): void {
    this.pageIndex = ev;
    this.refreshListData();
  }

  pageSizeChange(ev: any): void {
    this.pageSize = ev;
    this.refreshListData();
  }
}

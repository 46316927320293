<div class="alain-default__content-title">
  <div class="d-flex align-items-center">
    <div class="page-top-title"><span class="title-level-one">资产管理</span> / <span class="title-level-two">链路管理</span></div>
  </div>
</div>

<ng-template #rightTemplate>
  <!--  <button nz-button nzType="default" >设置安全等级</button>-->
  <button nz-button nzType="default" (click)="onSetThreshold()">设置报警总阈值</button>
  <button nz-button nzType="primary" (click)="onAdd()">新增</button>
</ng-template>
<nz-card style="width: 100%" [nzBordered]="false" nzTitle="链路列表" class="indicator-card" [nzExtra]="rightTemplate">
  <nz-table
    nzSize="middle"
    [nzFrontPagination]="false"
    [nzData]="listData"
    [nzLoading]="tableLoading"
    [nzScroll]="{ x: '1400px', y: '1000px' }"
  >
    <thead>
      <tr>
        <th>链路编号</th>
        <th>起点设备（编号）</th>
        <th>起点端口</th>
        <th>终点设备（编号）</th>
        <th>终点端口</th>
        <th>链路状态</th>
        <th nzWidth="250px" nzRight>操作</th>
      </tr>
    </thead>
    <tbody>
      <ng-container>
        <tr *ngFor="let data of listData; let i = index">
          <!--<td>{{ (pageIndex - 1) * pageSize + i + 1 }}</td>-->

          <td>{{ data?.linkSerial }}</td>
          <td>{{ data?.startDeviceInfo }}</td>
          <td>{{ data?.startDevicePort }}</td>
          <td>{{ data?.endDeviceInfo }}</td>
          <td>{{ data?.endDevicePort }}</td>
          <td>{{ data?.statusName }}</td>
          <!--        <td>{{ data?.type ? identityTypeEnum[data?.type] : '' }}</td>-->
          <td nzWidth="250px" nzRight>
            <a (click)="onEdit(data)">编辑</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a (click)="onLinkHistory(data)">链路流量</a>
            <nz-divider nzType="vertical" *ngIf="data.status == '1'"></nz-divider>
            <a nz-popconfirm nzPopconfirmTitle="是否确认禁用?" *ngIf="data.status == '1'" (nzOnConfirm)="disableLink(data?.id)">禁用</a>
            <nz-divider nzType="vertical" *ngIf="data.status == '4'"></nz-divider>
            <a nz-popconfirm nzPopconfirmTitle="是否确认启用?" *ngIf="data.status == '4'" (nzOnConfirm)="enableLink(data?.id)">启用</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a nz-popconfirm nzPopconfirmTitle="确定要删除该数据吗?" (nzOnConfirm)="deleteLink(data?.id)">删除</a>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
  <div class="table-pagination-box">
    <nz-pagination
      [nzPageIndex]="pageIndex"
      [nzTotal]="total"
      nzShowSizeChanger
      [nzPageSize]="pageSize"
      (nzPageIndexChange)="pageChange($event)"
      (nzPageSizeChange)="pageSizeChange($event)"
      [nzShowTotal]="totalTemplate"
    >
      <ng-template #totalTemplate let-total>共{{ total }}条</ng-template>
    </nz-pagination>
  </div>
</nz-card>

<!--新增编辑链路-->
<nz-modal *ngIf="isVisible" [(nzVisible)]="isVisible" [nzTitle]="title" nzClosable="false">
  <ng-container *nzModalContent>
    <form nz-form [formGroup]="form">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="startDeviceId" nzRequired>起点设备</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="请选择起点设备">
          <nz-select
            name="select-validate"
            nzPlaceHolder="请选择"
            nzShowSearch
            formControlName="startDeviceId"
            (ngModelChange)="onStartChange($event)"
          >
            <nz-option *ngFor="let item of deviceOptions" [nzValue]="item.id" [nzLabel]="item.deviceName"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="startDevicePort" nzRequired>起点接口</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="请填写起点接口">
          <nz-input-number
            style="width: 100%"
            formControlName="startDevicePort"
            [nzPlaceHolder]="'请输入'"
            [nzMin]="0"
            [nzStep]="1"
            [nzPrecision]="0"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="endDeviceId" nzRequired>终点设备</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="请选择终点设备">
          <nz-select
            name="select-validate"
            nzPlaceHolder="请选择"
            nzShowSearch
            formControlName="endDeviceId"
            (ngModelChange)="onEndChange($event)"
          >
            <nz-option *ngFor="let item of deviceOptions" [nzValue]="item.id" [nzLabel]="item.deviceName"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="endDevicePort" nzRequired>终点接口</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="请填写终点接口">
          <nz-input-number
            style="width: 100%"
            formControlName="endDevicePort"
            [nzPlaceHolder]="'请输入'"
            [nzMin]="0"
            [nzStep]="1"
            [nzPrecision]="0"
          ></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
  <div *nzModalFooter>
    <div style="margin-top: -5px">
      <button nz-button nzType="default" (click)="isVisible = false">取消</button>
      <button nz-button nzType="primary" [nzLoading]="loading" (click)="submit()" [disabled]="form.invalid">确定</button>
    </div>
  </div>
</nz-modal>

<!--批量设置阈值-->
<nz-modal *ngIf="isThresholdVisible" [(nzVisible)]="isThresholdVisible" nzTitle="设置阈值" nzClosable="false">
  <ng-container *nzModalContent>
    <form nz-form [formGroup]="thresholdForm">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="isOpen">是否开启</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <nz-switch formControlName="isOpen" nzCheckedChildren="开" nzUnCheckedChildren="关"></nz-switch>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="threshold">阈值</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <input nz-input formControlName="threshold" placeholder="请输入" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
  <div *nzModalFooter>
    <div style="margin-top: -5px">
      <button nz-button nzType="default" (click)="closeThreshold()">取消</button>
      <button nz-button nzType="primary" [nzLoading]="loading" (click)="submitThreshold()" [disabled]="thresholdForm.invalid">确定</button>
    </div>
  </div>
</nz-modal>

<!--链路流量-->
<app-link-history
  *ngIf="isLinkHistoryVisible"
  [isVisible]="isLinkHistoryVisible"
  [linkId]="linkId"
  (isVisibleChange)="isVisibleChange($event)"
>
</app-link-history>

<!--新增编辑设备能力-->
<nz-modal *ngIf="isVisible" [(nzVisible)]="isVisible" [nzTitle]="title" nzClosable="false">
  <ng-container *nzModalContent>
    <form nz-form [formGroup]="form">
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="roomId">所在机房</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <nz-select name="select-validate" nzPlaceHolder="请选择" nzAllowClear nzShowSearch formControlName="roomId">
            <nz-option *ngFor="let item of roomOptions" [nzValue]="item.id" [nzLabel]="item.roomName"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="startDeviceId" nzRequired>起点设备</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="请选择起点设备">
          <nz-select name="select-validate" nzPlaceHolder="请选择" nzShowSearch formControlName="startDeviceId">
            <nz-option *ngFor="let item of deviceOptions" [nzValue]="item.id" [nzLabel]="item.deviceName"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="num">情报板数量</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="请填写情报板数量">
          <nz-input-number style="width: 100%" nzPlaceHolder="请输入" formControlName="num" [nzMin]="1" [nzStep]="1" [nzPrecision]="0">
          </nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>
  <div *nzModalFooter>
    <div style="margin-top: -5px">
      <button nz-button nzType="default" (click)="close()">取消</button>
      <button nz-button nzType="primary" [nzLoading]="loading" (click)="submit()" [disabled]="form.invalid">确定</button>
    </div>
  </div>
</nz-modal>

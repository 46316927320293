import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { _HttpClient } from '@delon/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { API } from '@service/api';

@Component({
  selector: 'app-traffic-detail',
  templateUrl: './traffic-detail.component.html',
  styleUrls: ['./identity.scss'],
})
export class TrafficDetailComponent implements OnInit {
  @Input() id: any;
  @Input() detailInfo: any;
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() isRefresh = new EventEmitter<boolean>();

  set visible(ev: boolean) {
    this.isVisible = ev;
    this.isVisibleChange.emit(ev);
  }

  // tslint:disable-next-line:typedef
  get visible() {
    return (this.isVisible = false);
  }

  title = '端口流量明细';
  // 列表数据
  listData: any[] = [];
  portOptions = [];
  isSelectedIndex = 0;
  // 新增编辑数据类型数据
  isDatatypeVisible = false;
  deviceDatatypeInfo: any;
  form;
  tableLoading = false;
  pageSize = 10;
  pageIndex = 1;
  total = 0;

  constructor(private api: API, private fb: FormBuilder, public http: _HttpClient) {
    this.form = fb.group({
      port: [null, [Validators.required]],
      dateRange: [null],
    });
  }

  ngOnInit(): void {
    this.refreshData();
  }

  refreshData(): void {
    const formValue = this.form.value;
    const par = {
      ...this.form.value,
      id: this.id,
      createAtBegin: formValue.dateRange?.length === 2 ? formValue.dateRange[0] : '',
      createAtEnd: formValue.dateRange?.length === 2 ? formValue.dateRange[1] : '',
      pageNum: this.pageIndex,
      pageSize: this.pageSize,
    };
    this.http.post(this.api.IDENTITY_FLOW, par).subscribe((e) => {
      if (e.code === 200) {
        this.listData = e.data;
        this.total = e.total;
      }
    });
  }

  onReset(): void {
    this.form.reset({});
    this.pageIndex = 1;
    this.refreshData();
  }

  onSearch(): void {
    this.pageIndex = 1;
    this.refreshData();
  }

  pageChange(ev: number): void {
    this.pageIndex = ev;
    this.refreshData();
  }

  pageSizeChange(ev: number): void {
    this.pageSize = ev;
    this.refreshData();
  }

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }
}

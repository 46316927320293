// 身份类型
export const IdentityTypeEnum = {
  Device: '设备',
  Service: '服务',
  User: '用户',
  Default: '客户端',
  Router: '边缘路由',
};

// 身份注册类型
export const IdentityEnrollEnum = {
  ott: '一次性令牌',
  ottca: '证书',
  // UPDB: 'updb',
};

// 策略类型
export const PolicyTypeEnum = {
  Dial: '起点',
  Bind: '终点',
  // UPDB: 'updb',
};

// @ts-ignore
export const PageSizeOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

// 可视化接口刷新时长
export const VisualTime = 1000 * 60 * 5;

<!--新增编辑-->
<nz-modal *ngIf="isVisible" [(nzVisible)]="isVisible" [nzTitle]="title" nzClosable="false" [nzWidth]="'1200px'">
  <ng-container *nzModalContent>
    <form nz-form [formGroup]="form">
      <nz-form-item>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzRequired>名称</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <input nz-input formControlName="name" placeholder="请输入" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="4" [nzXs]="24">角色</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select formControlName="roleAttributes" nzShowSearch nzAllowClear nzMode="multiple" nzPlaceHolder="请选择">
            <nz-option *ngFor="let data of roles" [nzLabel]="data" [nzValue]="data"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzRequired>规则校验</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择" formControlName="typeId">
            <nz-option nzLabel="MAC地址校验" [nzValue]="'MAC'"></nz-option>
            <nz-option nzLabel="操作系统校验" [nzValue]="'OS'"></nz-option>
            <nz-option nzLabel="程序校验" [nzValue]="'PROCESS'"></nz-option>
            <nz-option nzLabel="复合程序校验" [nzValue]="'PROCESS_MULTI'"></nz-option>
            <nz-option nzLabel="Windows域校验" [nzValue]="'DOMAIN'"></nz-option>
            <nz-option nzLabel="多因素校验" [nzValue]="'MFA'"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <!--  MAC增加项-->
      <nz-form-item *ngIf="form.value.typeId == 'MAC'">
        <nz-form-label [nzSm]="4" [nzXs]="24" nzRequired>mac地址</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24" nzErrorTip="请填写MAC地址">
          <div class="range-filter">
            <input nz-input placeholder="请输入" formControlName="macAddress" />
            <button style="margin-left: 15px" nz-button nzType="primary" (click)="onAddMacAddress(form.value.macAddress)">添加</button>
          </div>
          <div class="add-box">
            <div class="add-each" *ngFor="let macAddress of macAddresses; let i = index">
              {{ macAddress }}
              <div class="close-icon" (click)="onDeleteMacAddress(i)">x</div>
            </div>
          </div>
        </nz-form-control>
      </nz-form-item>
      <!--  PROCESS增加项-->
      <div *ngIf="form.value.typeId == 'PROCESS'">
        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24" nzRequired>操作系统</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择" formControlName="osType">
              <nz-option nzLabel="Android" [nzValue]="'Android'"></nz-option>
              <nz-option nzLabel="Linux" [nzValue]="'Linux'"></nz-option>
              <nz-option nzLabel="Windows" [nzValue]="'Windows'"></nz-option>
              <nz-option nzLabel="macOS" [nzValue]="'macOS'"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24">HASHES</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <div class="range-filter">
              <input nz-input placeholder="请输入" formControlName="hash" />
              <button style="margin-left: 15px" nz-button nzType="primary" (click)="onAddEachHashChange(form.value.hash)">添加</button>
            </div>
            <div class="add-box">
              <div class="add-each" *ngFor="let hash of hashes; let i = index">
                {{ hash }}
                <div class="close-icon" (click)="onDeleteEachHash(i)">x</div>
              </div>
            </div>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24" nzRequired>路径</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <input nz-input placeholder="请输入" formControlName="path" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24">特征</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <input nz-input placeholder="请输入" formControlName="signerFingerprint" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <!--  DOMAIN增加项-->
      <div *ngIf="form.value.typeId == 'DOMAIN'">
        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24" nzRequired>域名</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <div class="range-filter">
              <input nz-input placeholder="请输入" formControlName="domian" />
              <button style="margin-left: 15px" nz-button nzType="primary" (click)="onAddDomainChange(form.value.domian)">添加</button>
            </div>
            <div class="add-box">
              <div class="add-each" *ngFor="let domian of domians; let i = index">
                {{ domian }}
                <div class="close-icon" (click)="onDeleteDomain(i)">x</div>
              </div>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <!--  MFA增加项-->
      <div *ngIf="form.value.typeId == 'MFA'">
        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24">超时时间（秒）</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <nz-input-number
              style="width: 100%"
              nzPlaceHolder="请输入"
              [nzMin]="0"
              [nzStep]="1"
              formControlName="timeoutSeconds"
              [nzPrecision]="2"
            ></nz-input-number>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24">要求唤醒</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <nz-switch nzCheckedChildren="是" nzUnCheckedChildren="否" formControlName="promptOnWake"></nz-switch>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label [nzSm]="4" [nzXs]="24">要求解锁</nz-form-label>
          <nz-form-control [nzSm]="18" [nzXs]="24">
            <nz-switch nzCheckedChildren="是" nzUnCheckedChildren="否" formControlName="promptOnUnlock"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
      <!--  PROCESS_MULTI语意-->
      <nz-form-item *ngIf="form.value.typeId == 'PROCESS_MULTI'">
        <nz-form-label [nzSm]="4" [nzXs]="24" nzRequired>语义</nz-form-label>
        <nz-form-control [nzSm]="18" [nzXs]="24">
          <nz-select nzShowSearch nzAllowClear nzPlaceHolder="请选择" formControlName="semantic">
            <nz-option nzLabel="全部匹配" [nzValue]="'AllOf'"></nz-option>
            <nz-option nzLabel="部分匹配" [nzValue]="'AnyOf'"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </form>

    <!--  OS增加项-->
    <div *ngIf="form.value.typeId == 'OS'">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="5" style="text-align: right; margin-bottom: 10px; font-size: 16px">操作系统：</div>
      </div>
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="3"></div>
        <div nz-col [nzSpan]="19" class="form-part-bg">
          <div nz-row [nzGutter]="24" *ngFor="let data of osData; let i = index">
            <div nz-col [nzSpan]="7">
              <nz-form-item>
                <nz-form-label [nzSm]="8" [nzXs]="24">{{ data.type }}</nz-form-label>
                <nz-form-control [nzSm]="16" [nzXs]="24">
                  <nz-switch
                    nzCheckedChildren="是"
                    nzUnCheckedChildren="否"
                    [(ngModel)]="data.isSwitch"
                    (ngModelChange)="onSwitchChange($event, i)"
                  ></nz-switch>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSpan]="17">
              <nz-form-item>
                <nz-form-label [nzSm]="4" [nzXs]="24">版本号范围</nz-form-label>
                <nz-form-control [nzSm]="19" [nzXs]="24">
                  <div class="range-filter">
                    <input nz-input placeholder="请输入#.#.#" [(ngModel)]="data.version[0]" (ngModelChange)="onMinChange($event, i)" />
                    <div class="divide-symbol">~</div>
                    <input nz-input placeholder="请输入#.#.#" [(ngModel)]="data.version[1]" (ngModelChange)="onMaxChange($event, i)" />
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  PROCESS_MULTI增加项-->
    <div *ngIf="form.value.typeId == 'PROCESS_MULTI'">
      <div nz-row [nzGutter]="24">
        <div nz-col [nzSpan]="2"></div>
        <div nz-col [nzSpan]="20">
          <nz-table nzSize="small" [nzFrontPagination]="false" [nzData]="processes" [nzScroll]="{ x: '900px', y: '400px' }">
            <thead>
              <tr>
                <th nzWidth="110px"><span class="require-icon">*</span>操作系统</th>
                <th nzWidth="300px">HASHES</th>
                <th><span class="require-icon">*</span>路径</th>
                <th nzWidth="300px">特征</th>
                <th nzWidth="80px" nzRight>
                  <button nz-button nzType="primary" nzSize="small" (click)="onAdd()">添加</button>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <tr *ngFor="let data of processes; let i = index">
                  <td nzWidth="110px">
                    <nz-select
                      style="width: 100px"
                      nzShowSearch
                      nzAllowClear
                      nzPlaceHolder="请选择"
                      [(ngModel)]="data.osType"
                      (ngModelChange)="onOsTypeChange($event, i)"
                    >
                      <nz-option nzLabel="Android" [nzValue]="'Android'"></nz-option>
                      <nz-option nzLabel="Linux" [nzValue]="'Linux'"></nz-option>
                      <nz-option nzLabel="Windows" [nzValue]="'Windows'"></nz-option>
                      <nz-option nzLabel="macOS" [nzValue]="'macOS'"></nz-option>
                    </nz-select>
                  </td>
                  <td nzWidth="300px">
                    <div class="batch-input-box">
                      <input
                        style="border: none"
                        nz-input
                        placeholder="请输入"
                        [(ngModel)]="data.hash"
                        (ngModelChange)="onHashChange($event, i)"
                        (keydown.enter)="onAddHashChange(data.hash, i)"
                      />
                      <div class="add-box">
                        <div class="add-each" *ngFor="let hash of data.hashes; let j = index">
                          {{ hash }}
                          <div class="close-icon" (click)="onDeleteHash(i, j)">x</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input nz-input placeholder="请输入" [(ngModel)]="data.path" (ngModelChange)="onPathChange($event, i)" />
                  </td>
                  <td nzWidth="300px">
                    <div class="batch-input-box">
                      <input
                        style="border: none"
                        nz-input
                        placeholder="请输入"
                        [(ngModel)]="data.finger"
                        (ngModelChange)="onFingerChange($event, i)"
                        (keydown.enter)="onAddFingerChange(data.finger, i)"
                      />
                      <div class="add-box">
                        <div class="add-each" *ngFor="let finger of data.fingers; let j = index">
                          {{ finger }}
                          <div class="close-icon" (click)="onDeleteFinger(i, j)">x</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td nzWidth="80px" nzRight>
                    <a (click)="onDelete(i)">删除</a>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </ng-container>

  <div *nzModalFooter>
    <div style="margin-top: -5px">
      <button nz-button nzType="default" (click)="close()">取消</button>
      <button nz-button nzType="primary" [nzLoading]="loading" (click)="submit()" [disabled]="form.invalid">确定</button>
    </div>
  </div>
</nz-modal>

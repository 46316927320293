import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { FormBuilder, Validators } from '@angular/forms';
import { API } from '@service/api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ShowyePageAuthService } from '@showye/showye-page-base';

@Component({
  selector: 'app-posture-edit',
  templateUrl: './posture-edit.component.html',
  styleUrls: ['./identity.scss'],
})
export class PostureEditComponent implements OnInit {
  @Input() id: any;
  @Input() info: any;
  @Input() isVisible = false;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() isRefresh = new EventEmitter<boolean>();

  set visible(ev: boolean) {
    this.isVisible = ev;
    this.isVisibleChange.emit(ev);
  }

  // tslint:disable-next-line:typedef
  get visible() {
    return (this.isVisible = false);
  }

  // 新增编辑表单
  form: any;
  title = '新增规则校验';
  loading = false;
  roles: any[] = [];
  types: any[] = [];
  osData: any[] = [
    { type: 'Android', isSwitch: false, version: ['', ''] },
    { type: 'Linux', isSwitch: false, version: ['', ''] },
    { type: 'Windows', isSwitch: false, version: ['', ''] },
    { type: 'macOS', isSwitch: false, version: ['', ''] },
  ];
  processes: any[] = [];
  macAddresses: any[] = [];
  hashes: any[] = [];
  domians: any[] = [];

  constructor(
    private api: API,
    private fb: FormBuilder,
    public http: _HttpClient,
    public msg: NzMessageService,
    public notify: NzNotificationService,
  ) {
    this.form = fb.group({
      name: [null, [Validators.required]],
      roleAttributes: [null],
      typeId: [null, [Validators.required]], // MAC OS PROCESS  PROCESS_MULTI  DOMAIN MFA
      // MAC
      macAddress: [null, [Validators.pattern(/^([0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2}$/)]],
      macAddresses: [null],
      // os类型--> operationSystems:[]
      // PROCESS类型 process:{}
      osType: [null],
      hash: [null],
      hashes: [null],
      path: [null],
      signerFingerprint: [null],
      // PROCESS_MULTI
      semantic: [null],
      // DOMAIN类型
      domian: [null],
      domians: [null],
      // MFA类型
      timeoutSeconds: [null],
      promptOnWake: [false],
      promptOnUnlock: [false],
    });
  }

  ngOnInit(): void {
    this.getRoleByIDENTITYS();
    this.getCheckType();
    this.id ? (this.title = '编辑规则校验') : (this.title = '新增规则校验');
  }

  onAddMacAddress(ev: any): void {
    const temp = /^([0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2}$/;
    if (ev && temp.test(ev)) {
      this.macAddresses.push(ev);
      this.form.patchValue({ macAddress: '', macAddresses: this.macAddresses });
    }
  }

  onDeleteMacAddress(ev: number): void {
    this.macAddresses = this.macAddresses.filter((x: any, i: number) => i !== ev);
    this.form.patchValue({ macAddresses: this.macAddresses });
  }

  onAddEachHashChange(ev: any): void {
    if (ev) {
      this.hashes.push(ev);
      this.form.patchValue({ hash: '', hashes: this.hashes });
    }
  }

  onDeleteEachHash(ev: number): void {
    this.hashes = this.hashes.filter((x: any, i: number) => i !== ev);
    this.form.patchValue({ hashes: this.hashes });
  }

  onAddDomainChange(ev: any): void {
    if (ev) {
      this.domians.push(ev);
      this.form.patchValue({ domian: '', domians: this.domians });
    }
  }

  onDeleteDomain(ev: number): void {
    this.domians = this.domians.filter((x: any, i: number) => i !== ev);
    this.form.patchValue({ domians: this.domians });
  }

  onSwitchChange(ev: any, i: number): void {
    this.osData[i].isSwitch = ev;
  }

  onMinChange(ev: any, i: number): void {
    this.osData[i].version[0] = ev;
  }

  onMaxChange(ev: any, i: number): void {
    this.osData[i].version[1] = ev;
  }

  onAdd(): void {
    const eachItem = {
      osType: '',
      hash: '',
      hashes: [],
      path: '',
      finger: '',
      fingers: [],
    };
    this.processes.push(eachItem);
  }

  onDelete(ev: number): void {
    this.processes = this.processes.filter((x: any, i: number) => i !== ev);
  }

  onOsTypeChange(ev: any, i: number): void {
    this.processes[i].osType = ev;
  }

  onAddHashChange(ev: any, i: number): void {
    if (ev) {
      this.processes[i].hashes.push(ev);
      this.processes[i].hash = '';
    }
  }

  onAddFingerChange(ev: any, i: number): void {
    if (ev) {
      this.processes[i].fingers.push(ev);
      this.processes[i].finger = '';
    }
  }

  onDeleteHash(i: number, j: number): void {
    this.processes[i].hashes = this.processes[i].hashes.filter((x: any, g: number) => g !== j);
  }

  onDeleteFinger(i: number, j: number): void {
    this.processes[i].fingers = this.processes[i].fingers.filter((x: any, g: number) => g !== j);
  }

  onHashChange(ev: any, i: number): void {
    this.processes[i].hash = ev;
  }

  onPathChange(ev: any, i: number): void {
    this.processes[i].path = ev;
  }

  onFingerChange(ev: any, i: number): void {
    this.processes[i].finger = ev;
  }

  close(): void {
    this.isVisible = false;
    this.isVisibleChange.emit(false);
  }

  // 获取检查类型类别
  getCheckType(): void {
    this.http.get(this.api.POSTURE_CHECK_TYPES).subscribe((e: any) => {
      if (e.code === 200) {
        this.types = e.data || [];
      }
    });
  }

  // 详情回显
  getInfoShow(): void {
    if (this.id) {
      // console.log(this.info);
      switch (this.info.typeId) {
        // MAC OS PROCESS  PROCESS_MULTI  DOMAIN MFA
        case 'MAC':
          this.macAddresses = this.info.macAddresses || [];
          break;
        case 'OS':
          this.info.operatingSystems.forEach((x: any) => {
            this.osData.forEach((y: any) => {
              if (x.type === y.type) {
                y.isSwitch = true;
                y.version = x.versions;
              }
            });
          });
          break;
        case 'PROCESS':
          this.info.hashes = this.info.process.hashes || [];
          this.info.osType = this.info.process.osType;
          this.info.path = this.info.process.path;
          this.info.signerFingerprint = this.info.process.signerFingerprint;
          this.hashes = this.info.hashes;
          break;
        case 'PROCESS_MULTI':
          const eachItem = {
            osType: '',
            hash: '',
            hashes: [],
            path: '',
            finger: '',
            fingers: [],
          };
          this.processes = [];
          this.info.processes.forEach((x: any) => {
            this.processes.push({
              osType: x.osType,
              hash: '',
              hashes: x.hashes || [],
              path: x.path,
              finger: '',
              fingers: x.signerFingerprint || [],
            });
          });
          break;
        case 'DOMAIN':
          this.domians = this.info.domains;
          break;
        case 'MFA':
          break;
        default:
          break;
      }
      this.form.reset(this.info);
    }
  }

  // 获取角色列表
  getRoleByIDENTITYS(): void {
    this.http.get(this.api.IDENTITY_ROLES).subscribe((e: any) => {
      if (e.code === 200) {
        this.roles = e.data || [];
        this.getInfoShow();
      }
    });
  }

  submit(): void {
    const formValue = this.form.value;
    const par: any = {
      name: formValue.name,
      roleAttributes: formValue.roleAttributes || [],
      typeId: formValue.typeId,
    };
    switch (formValue.typeId) {
      // MAC OS PROCESS  PROCESS_MULTI  DOMAIN MFA
      case 'MAC':
        if (this.macAddresses?.length > 0) {
          par.macAddresses = this.macAddresses;
        } else {
          this.notify.error('请选择mac地址', '');
          return;
        }
        break;
      case 'OS':
        par.operatingSystems = [];
        this.osData.forEach((x: any) => {
          if (x.isSwitch) {
            const versions = x.version.filter((y: any) => y);
            par.operatingSystems.push({
              type: x.type,
              versions,
            });
          }
        });
        break;
      case 'PROCESS':
        if (!formValue.osType) {
          this.notify.error('请选择操作系统', '');
          return;
        }
        if (!formValue.path) {
          this.notify.error('请输入路径', '');
          return;
        }
        par.process = {
          osType: formValue.osType,
          hashes: this.hashes || [],
          path: formValue.path,
          signerFingerprint: formValue.signerFingerprint,
        };
        break;
      case 'PROCESS_MULTI':
        par.semantic = formValue.semantic;
        par.processes = [];
        this.processes.forEach((x: any) => {
          par.processes.push({
            osType: x.osType,
            hashes: x.hashes || [],
            path: x.path,
            signerFingerprint: x.fingers || [],
          });
        });
        break;
      case 'DOMAIN':
        if (this.domians?.length > 0) {
          par.domains = this.domians;
        } else {
          this.notify.error('请添加domains', '');
          return;
        }
        break;
      case 'MFA':
        par.timeoutSeconds = formValue.timeoutSeconds;
        par.promptOnWake = formValue.promptOnWake;
        par.promptOnUnlock = formValue.promptOnUnlock;
        break;
      default:
        break;
    }
    if (!this.id) {
      this.loading = true;
      this.http.post(this.api.ADD_POSTURE_CHECK, par).subscribe(
        (e: any) => {
          if (e.code === 200) {
            this.loading = false;
            this.notify.success('新增成功', '');
            this.isRefresh.emit(true);
            this.close();
          }
        },
        () => (this.loading = false),
      );
    } else {
      this.loading = true;
      this.http.post(this.api.EDIT_POSTURE_CHECK(this.id), par).subscribe(
        (e: any) => {
          if (e.code === 200) {
            this.loading = false;
            this.notify.success('编辑成功', '');
            this.isRefresh.emit(true);
            this.close();
          }
        },
        () => (this.loading = false),
      );
    }
  }
}

// 设备状态
export const deviceStatus = [
  { label: '在线', value: '1' },
  { label: '离线', value: '2' },
  { label: '报警', value: '3' },
  { label: '禁用', value: '4' },
  { label: '已删除', value: '99' },
];

export const deviceStatusEnum = {
  1: '在线',
  2: '离线',
  3: '报警',
  4: '禁用',
  99: '已删除',
};

// 设备类型
export const deviceType = [
  { label: '量子安全路由器', value: '1' },
  { label: '摄像头', value: '2' },
  { label: '显示屏', value: '3' },
  { label: 'VPN', value: '4' },
  { label: '情报板', value: '5' },
  { label: '服务器', value: '6' },
];
export const deviceTypeEnum = {
  1: '量子安全路由器',
  2: '摄像头',
  3: '显示屏',
  4: 'VPN',
  5: '情报板',
  6: '服务器',
};

// 告警状态
export const alarmStatusEnum = {
  1: '已处理',
  2: '未处理',
  3: '已忽略',
};
export const alarmStatus = [
  { name: '已处理', value: '1' },
  { name: '未处理', value: '2' },
  { name: '已忽略', value: '3' },
];

// 告警类型
export const alarmTypes = [
  { name: '设备报警', value: '1' },
  { name: '链路报警', value: '2' },
  { name: '其他', value: '3' },
];

export const alarmTypeEnum = {
  1: '设备报警',
  2: '链路报警',
  3: '其他',
};

// 告警事件
export const alarmEventTypes = [
  { name: '设备报警', value: '1' },
  { name: '链路报警', value: '2' },
  { name: '其他', value: '3' },
];

// 报警设备/链路
export const typeOptions = [
  // 设备
  { name: '设备温度过高', value: '1' },
  { name: '内存不足', value: '2' },
  { name: '入向流量过高', value: '3' },
  { name: '出向流量过高', value: '4' },
  { name: 'cpu利用率过高', value: '5' },
  { name: '设备异常', value: '6' },
  { name: '受到攻击', value: '8' },
  { name: '进程数过多', value: '9' },
  // 链路
  { name: '流量过高', value: '7' },
];

// 攻击类型
export const attackTypesEnum = {
  1: '非法扫描',
};

// 链路状态
export const linkStatusOptions = [
  { name: '正常', value: '1' },
  { name: '断开', value: '2' },
  { name: '报警', value: '3' },
  { name: '禁用', value: '4' },
];

export const linkStatusEnum = {
  1: '正常',
  2: '断开',
  3: '报警',
  4: '禁用',
};
